<style lang="scss" scoped>
@import "../../../../sass/variables";
.title_wrap {
    span {
        &::before {
            content: '\f518';
            font-family: "Font Awesome 5 Free";
            font-weight: bold;
            font-size: 1.6rem;
            margin: 0 0.3rem 0 0;
        }
    }
}
.recipe-ranking {
    .recipes {
        display: flex;
        .recipe {
            width: calc(100% / 3);
            position: relative;
            border-top: 1px solid $border-color;
            border-bottom: 1px solid $border-color;
            &:not(:first-of-type) {
                border-left: 1px solid $border-color;
            }
            a {
                height: 100%;
                display: block;
                color: #fff;
                img {
                    width: calc(100% - 0.2rem);
                    margin: 0.2rem 0.1rem;
                    border-radius: 8px;
                    object-fit: cover;
                    aspect-ratio: 1 / 1;
                    transition:0.3s;
                    &:hover {
                        opacity:0.8;
                    }
                }
                .rank-number {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: absolute;
                    top: 0.5rem;
                    left: 0.5rem;
                    width: 22px;
                    height: 22px;
                    border-radius: 4px;
                    background-color: $main-color-orange;
                    &.number-1 {
                        background-color: #b2b2b2;
                    }
                    &.number-2 {
                        background-color: #9d7108;
                    }
                }
                h3 {
                    position: absolute;
                    width: 95%;
                    z-index: 1;
                    font-size: 0.9rem;
                    margin: 0;
                    bottom: 1.5rem;
                    left: 0.4rem;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    text-shadow: 0px 0px 3px #000;
                }
                p {
                    position: absolute;
                    width: 60%;
                    text-align: end;
                    z-index: 1;
                    font-size: 0.6rem;
                    margin: 0;
                    bottom: 0.2rem;
                    right: 0.4rem;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    text-shadow: 0px 0px 3px #000;
                }
            }
        }
    }
}
.myfood-new {
    width: 95%;
    margin: 0 auto;
    .myfood.row {
        border: 1px solid $border-color;
        margin: 0;
        border-radius: 1rem;
        padding: 1rem 0 0.5rem;
        text-align: initial;
        background-color: #fff;
        transition: 0.3s;
        &:not(:first-of-type) {
            margin: 0.5rem 0 0;
        }
        &:hover {
            cursor: pointer;
            box-shadow: 0px 0px 10px $border-color;
        }
        h3 {
            font-size: 1rem;
            font-weight: bold;
            margin: 0;
            span {
                font-size: 0.8rem;
                font-weight: normal;
            }
            &::before {
                content: "";
                display: inline-block;
                width: 0.6rem;
                height: 0.6rem;
                background-color: $main-color-red;
                margin: 0 0.3rem 0 0;
                transform: translateY(-0.1rem);
            }
        }
        .member {
            font-size: 0.7rem;
            text-align: right;
            &::before {
                content: "\f007";
                font-family: "Font Awesome 5 Free";
                font-weight: bold;
                margin: 0 0.1rem 0 0;
            }
        }
        p {
            font-size: 0.8rem;
            margin: 0 0 0.2rem;
        }
        .flex {
            display: flex;
            align-items: center;
            img {
                width: 65px;
                object-fit: cover;
                aspect-ratio: 1 / 1;
                margin: 0 0.2rem 0 0;
            }
        }
    }
}
@media screen and (min-width: 1000px) {
    .recipe-ranking, .recipe-new {
        padding: 0 2rem;
    }
}
</style>
<template>
<div class="user_contents">
    <div class="title_wrap">
        <span>レシピ</span>
    </div>
    <search-component></search-component>
    <div class="recipe-ranking">
        <h2 class="recipe-title">人気レシピ</h2>
        <div class="recipes">
            <div class="recipe" v-for="(recipe,i) in rankingList" :key="i">
                <router-link :to="`/recipe/${recipe.id}`">
                    <img v-if="recipe.thumbnail" :src="`https://healtheat.s3.ap-northeast-1.amazonaws.com${recipe.thumbnail}`" :alt="`${recipe.name}の画像`">
                    <img v-if="!recipe.thumbnail" :src="NoImgUrl" :alt="`${recipe.name}の画像`">
                    <div class="rank-number" :class="['number-' + i]">{{i + 1}}</div>
                    <h3>{{recipe.name}}</h3>
                    <p>by{{recipe.member.name}}</p>
                </router-link>
            </div>
        </div>
        <div class="more-recipe">
            <router-link to="/recipe/ranking">人気レシピをもっと見る</router-link>
        </div>
    </div>
    <adsense type="2" />
    <div class="recipe-new">
        <h2 class="recipe-title">新着レシピ</h2>
        <div class="recipes">
            <div class="recipe" v-for="(recipe,i) in newList" :key="i">
                <div v-if="!recipe.ad">
                    <router-link :to="`/recipe/${recipe.id}`">
                        <img v-if="recipe.thumbnail" :src="`https://healtheat.s3.ap-northeast-1.amazonaws.com${recipe.thumbnail}`" :alt="`${recipe.name}の画像`">
                        <img v-if="!recipe.thumbnail" :src="NoImgUrl" :alt="`${recipe.name}の画像`">
                    </router-link>
                    <router-link :to="`/recipe/${recipe.id}`">
                        <h3>{{recipe.name}}</h3>
                    </router-link>
                    <div class="member-name">
                        <router-link :to="`/member/${recipe.member.uuid}`">by{{recipe.member.name}}</router-link>
                    </div>
                </div>
                <ad-banner class="recipe-ad" v-if="recipe.ad" type="0" text="true" />
            </div>
        </div>
        <div class="more-recipe">
            <router-link to="/recipe">新着レシピをもっと見る</router-link>
        </div>
    </div>
    <adsense type="2" />
    <div class="myfood-new">
        <h2 class="recipe-title">みんなのMY食品</h2>
        <div @click="myfoodClick" class="myfood row" v-for="(myfood,i) in myfoodList" :key="i">
            <h3>{{myfood.name}}<span>{{myfood.brand_name}}</span><span>（{{myfood.weight}}g）</span></h3>
            <span class="member">{{myfood.member.name}}</span>
            <div class="flex">
                <div>
                    <img v-if="myfood.image" :src="`https://healtheat.s3.ap-northeast-1.amazonaws.com${myfood.image}`" :alt="`${myfood.name}の画像`">
                    <img v-if="!myfood.image" :src="NoImgUrl" :alt="`${myfood.name}の画像`">
                </div>
                <div class="row">
                    <div class="col-12">
                        <p>エネルギー：{{myfood.energy}}kcal</p>
                    </div>
                    <div class="col-6">
                        <p>たんぱく質：{{myfood.protein}}g</p>
                    </div>
                    <div class="col-6">
                        <p>脂質：{{myfood.lipid}}g</p>
                    </div>
                    <div class="col-6">
                        <p>食物繊維：{{myfood.dietary_fiber}}g</p>
                    </div>
                    <div class="col-6">
                        <p>炭水化物：{{myfood.carbohydrate}}g</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="more-recipe">
            <router-link to="/myfood">みんなのMY食品をもっと見る</router-link>
        </div>
    </div>
    <adsense type="2" />
    <div class="column-new">
        <h2 class="recipe-title">コラム</h2>
        <div class="column row" v-for="(column,i) in columnList" :key="i">
            <div class="flex">
                <div @click="columnClick(column)">
                    <img v-if="column.image" :src="`https://healtheat.s3.ap-northeast-1.amazonaws.com${column.image}`" :alt="`${column.title}の画像`">
                    <img v-if="!column.image" :src="NoImgUrl" :alt="`${column.title}の画像`">
                </div>
                <div>
                    <h3 @click="columnClick(column)" class="title">{{column.title}}</h3>
                    <p @click="columnClick(column)">{{column.body}}</p>
                </div>
            </div>
        </div>
        <div class="more-recipe">
            <router-link to="/column">コラムをもっと見る</router-link>
        </div>
    </div>
    <adsense type="2" />
</div>
</template>
<script>
import searchComponent from "./search-component.vue";
import Api from "../../../common/fetch-wrapper";
import {NO_IMG_URL} from "../../../common/constants";
import AdBanner from "../../../components/ad-banner.vue";
import Adsense from "../../../components/adsense.vue";
import {mapGetters} from "vuex";
import { USER_ARIES } from "../../../store/modules/user-store";


export default {
    components: {
        "search-component" : searchComponent,
        "ad-banner" : AdBanner,
        "adsense" : Adsense,
    },
    data() {
        return {
            rankingList: [],
            newList: [],
            myfoodList: [],
            columnList: [],
            NoImgUrl: NO_IMG_URL,
        }
    },
    computed: {
        ...mapGetters(USER_ARIES,["userPremium"]),
    },
    methods: {
        getNewRecipe:function() {
            Api.get("/recipe/new").then(x => {
                if(!this.userPremium) {
                    x.forEach(function(e, index){
                        e.ad = false;
                        if((index + 1) % 5 == 0) {
                            x.splice(index, -1, {ad: true})
                        }
                    });
                }
                this.newList = x;
            });
        },
        getRankingRecipe:function() {
            Api.get("/recipe/ranking/top").then(x => {
                this.rankingList = x;
            });
        },
        getNewMyfood:function() {
            Api.get("/myfood/new").then(x => {
                this.myfoodList = x;
            });
        },
        getColumn:function() {
            Api.get("/column/new").then(x => {
                this.columnList = x;
            });
        },
        myfoodClick:function() {
            this.$router.push({path:"/myfood"}).catch(err => {});
        },
        columnClick:function(data) {
            if(data.type == 1){
                this.$router.push({path:`/recipe-summary/${data.id}`}).catch(err => {});
            } else {
                this.$router.push({path:`/column/${data.id}`}).catch(err => {});
            }
        },
    },
    mounted:function() {
        this.getRankingRecipe();
        this.getNewRecipe();
        this.getNewMyfood();
        this.getColumn();
    },
}
</script>