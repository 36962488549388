<style lang="scss" scoped>
@import "../../sass/variables";
.bottom_menu {
    display: flex;
    position: fixed;
    bottom: 0;
    width: 100vw;
    background-color: $main-color-green;
    padding-bottom: env(safe-area-inset-bottom);
    z-index: 9;
	.menu-item {
		background-color: $main-color-light-green;
		border-radius: 6px;
		display: inline-block;
		text-decoration: none;
		width: calc(100% / 4);
		height: 56px;
		margin: 2px;
		.button {
			font-size: 12px;
			span {
				color: $black-font;
				display: block;
    			text-align: center;
				&::before {
					font-family: "Font Awesome 5 Free";
					font-weight: bold;
					display: block;
					font-size: 24px;
				}
				&.calculator::before {
					content: '\f1ec';
				}
				&.recipe::before {
					content: '\f518';
				}
				&.food::before {
                	content: '\f787';
				}
				&.login::before {
					content: '\f2f6';
				}
				&.myrecipe::before {
					content: '\f573';
				}
				&.profile {
					letter-spacing: -0.1rem;
					&::before {
						content: '\f007';
					}
				}
			}
		}
	}
}
@media screen and (min-width: 1000px) {
    .bottom_menu {
        display: none;
    }
}
</style>
<template>
<div class="bottom_menu">
    <router-link to="/" class="menu-item">
        <div class="button"><span class="recipe">レシピ</span></div>
    </router-link>
    <router-link to="/calculator" class="menu-item">
        <div class="button"><span class="calculator">栄養価計算</span></div>
    </router-link>
    <router-link v-if="logined" to="/myfood/custom" class="menu-item">
        <div class="button"><span class="food">MY食品</span></div>
    </router-link>
    <router-link v-if="!logined" to="/myfood" class="menu-item">
        <div class="button"><span class="food">MY食品</span></div>
    </router-link>
    <router-link v-if="!logined" to="/login" class="menu-item">
        <div class="button"><span class="login">ログイン</span></div>
    </router-link>
    <router-link v-if="logined" to="/member/recipe" class="menu-item">
        <div class="button"><span class="myrecipe">レシピ管理</span></div>
    </router-link>
    <router-link v-if="logined" :to="`/member/${profileId}`" class="menu-item">
        <div class="button"><span class="profile">プロフィール</span></div>
    </router-link>
</div>
</template>
<script>
import {mapGetters} from "vuex";
import { USER_ARIES } from "../store/modules/user-store";
export default {
    computed: {
        ...mapGetters(USER_ARIES,["logined","profileId"]),
    },
}
</script>