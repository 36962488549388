<style lang="scss" scoped>
@import "../../../../sass/variables";
.title_wrap {
    span {
        &::before {
            content: '\f1ec';
            font-family: "Font Awesome 5 Free";
            font-weight: bold;
            font-size: 1.6rem;
            margin: 0 0.3rem 0 0;
        }
    }
}
h2 {
    font-size: 0.9rem;
    margin: 0.4rem 0.4rem 0;
}
.main_result {
    display: flex;
    padding: 1rem 0.5rem;
    .search_area {
        width: 35%;
        padding: 1rem 0 0;
        .serving_count {
            display: flex;
            align-items: end;
            font-size: 1.2rem;
            border-bottom: 5px solid $main-color-orange;
            padding: 0 0 0.2rem;
            input {
                width: 5rem;
                display: inline-block;
                border-color: $main-color-orange;
                background-color: #fff;
                padding: 0.2rem 0.75rem;
            }
            span {
                font-size: 0.9rem;
            }
        }
        .add_food {
            padding: 0.5rem 0;
            p {
                margin: 0;
            }
            .suggest {
                input {
                    border-radius: 8px;
                    padding: 0.5rem;
                    border-color: $main-color-orange;
                    background-color: rgba($color: $main-color-orange, $alpha: 0.1);
                }
            }
            .btn {
                color: #fff;
                padding: 0.2rem 0.6rem;
                margin: 0.5rem 0 0;
                border-radius: 8px;
                transition: 0.3s;
                &.add-myfood {
                    background-color: $main-color-red;
                }
                &:hover {
                    cursor: pointer;
                    box-shadow: 0 0 0 3px #f2e8d6;
                }
            }
        }
    }
    .result_area {
        position: relative;
        width: 65%;
        margin: 0 0 0 0.2rem;
        background-color: $main-color-yellow;
        transition: 0.3s;
        &:hover {
            cursor: pointer;
            box-shadow: 0px 0px 10px $border-color;
        }
        .result_title {
            position: absolute;
            top: 2px;
            left: 10%;
            text-align: center;
            font-size: 0.8rem;
            span {
                background-color: $main-color-yellow;
            }
        }
        .result_wrap {
            display: flex;
            flex-wrap: wrap;
            border: 1px solid $black-font;
            border-radius: 6px;
            margin: 0.5rem;
            padding: 1rem;
            height: calc(100% - 1rem);
            .result {
                width: 50%;
                &:first-of-type {
                    display: flex;
                    width: 100%;
                    font-weight: bold;
                    margin: 0 0 0.5rem;
                    span {
                        margin: 0 0.5rem 0 0;
                    }
                }
                p {
                    margin: 0 0 0.2rem;
                }
            }
        }
    }
}
.delete-btn {
    transition: 0.3s;
    &:hover {
        cursor: pointer;
        opacity: 0.8;
    }
}
.add-recipe {
    text-align: center;
    width: 80%;
    display: block;
    color: #fff;
    background-color: $main-color-green;
    border: none;
    border-radius: 6px;
    padding: 0.4rem 4rem;
    margin: 1rem auto 0;
    transition: 0.3s;
    &:hover {
        cursor: pointer;
        box-shadow: 0 0 0 3px #d6e7c1;
    }
}
@media screen and (min-width: 1000px) {
    h2 {
        padding: 1rem 2rem;
    }
    .main_result {
        padding: 0 2rem 1rem;
        .search_area {
            width: 30%;
            .serving_count {
                display: inline-flex;
                align-items: end;
                padding: 0 1rem 0.2rem 0;
            }
            .add_food {
                .suggest {
                    input {
                        padding: 0.2rem 0.5rem;
                    }
                }
            }
        }
        .result_area {
            width: 40%;
            margin: 0 0 0 2rem;
        }
    }
}
</style>
<template>
    <div>
        <div class="title_wrap">
            <span>栄養価計算</span>
        </div>
        <h2>このページでは調理に使用した材料から 1 食分の栄養素を算出することが出来ます。</h2>
        <div class="main_result">
            <div class="search_area">
                <div class="serving_count">
                    <input class="form-control" type="text" inputmode="decimal" @input="servingResult" v-model="servingCount">
                    <span>食分</span>
                </div>
                <div class="add_food">
                    <p>食材を入力</p>
                    <vue-simple-suggest
                        :value="keyword"
                        display-attribute="display_name"
                        :list="preData"
                        :filter="vueSimpleSuggestfilter"
                        :filter-by-query="true"
                        :max-suggestions="200"
                        @suggestion-click="suggestionClick"
                        @select="suggestionClick"
                        class="suggest"
                    >
                        <input type="text" v-model="keyword">
                    </vue-simple-suggest>
                    <button class="btn add-myfood" @click="myfoodClick">MY食品を登録</button>
                    <button class="btn btn-secondary reset" @click="reset">リセット</button>
                </div>
            </div>
            <div class="result_area" @click="openDetail(totalServingData, true)">
                <h3 class="result_title">
                    <span class="disp-sp">1食分の成分（タップで確認）</span>
                    <span class="disp-pc">1食分の成分（クリックで確認）</span>
                </h3>
                <div class="result_wrap">
                    <div class="result">
                        <span>カロリー</span>
                        <p>{{mathRound(totalServingData.energy)}}kcal</p>
                    </div>
                    <div class="result">
                        <span>たんぱく質</span>
                        <p>{{mathRound(totalServingData.protein)}}g</p>
                    </div>
                    <div class="result">
                        <span>脂質</span>
                        <p>{{mathRound(totalServingData.lipid)}}g</p>
                    </div>
                    <div class="result">
                        <span>炭水化物</span>
                        <p>{{mathRound(totalServingData.carbohydrate)}}g</p>
                    </div>
                    <div class="result">
                        <span>食物繊維</span>
                        <p>{{mathRound(totalServingData.dietary_fiber)}}g</p>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="isShow" class="vue-table table-responsive">
            <v-table ref="vuetable" :api-mode="false" pagination-path="" :fields="fields"
                :data="list"
                noDataTemplate = "食材を入力してください"
                class="user-table"
            >
                <div slot="delete" slot-scope="props" class="delete"><span class="delete-btn" @click="deleteDetail(props.rowIndex,props.rowData.display_name ? props.rowData.display_name : props.rowData.name)">×</span></div>
                <div slot="name" slot-scope="props" class="name" @click="openDetail(props.rowData)">{{props.rowData.display_name ? props.rowData.display_name : props.rowData.name}}</div>
                <div slot="weight" slot-scope="props" class="action" @click="noEvent">
                    <div v-if="props.rowData.weightTypeValue && props.rowData.weightTypeValue.name != 'g'">
                        <span v-if="props.rowData.weightTypeValue.name == '大さじ' || props.rowData.weightTypeValue.name == '小さじ'">{{props.rowData.weightTypeValue.name}}</span>
                        <input class="form-control type-weight" type="text" inputmode="decimal" @input="changeWeightType(props.rowData, props.rowData.weightTypeValue, props.rowIndex)" v-model="props.rowData.weightTypeValue.one_weight">
                        <span v-if="props.rowData.weightTypeValue.name != '大さじ' && props.rowData.weightTypeValue.name != '小さじ'">{{props.rowData.weightTypeValue.name}}</span>
                        （{{props.rowData.weight}}g）
                    </div>
                    <div v-if="!props.rowData.weightTypeValue || props.rowData.weightTypeValue.name == 'g'">
                        <input class="form-control" type="text" inputmode="decimal" @input="changeWeight(props.rowData, props.rowIndex)" v-model="props.rowData.weight">g
                    </div>
                </div>
                <div slot="energy" slot-scope="props" class="energy" @click="openDetail(props.rowData)">{{props.rowData.energy}}kcal</div>
                <div slot="protein" slot-scope="props" class="protein" @click="openDetail(props.rowData)">{{props.rowData.protein}}g</div>
                <div slot="lipid" slot-scope="props" class="lipid" @click="openDetail(props.rowData)">{{props.rowData.lipid}}g</div>
                <div slot="carbohydrate" slot-scope="props" class="carbohydrate" @click="openDetail(props.rowData)">{{props.rowData.carbohydrate}}g</div>
                <div slot="dietary_fiber" slot-scope="props" class="dietary_fiber" @click="openDetail(props.rowData)">{{props.rowData.dietary_fiber}}g</div>
                <div slot="salt" slot-scope="props" class="salt" @click="openDetail(props.rowData)">{{props.rowData.salt}}g</div>
                <template slot="tableFooter">
                    <template>
                        <tr class="serving_result" v-show="servingCount != 1" @click="openDetail(totalServingData, true)">
                            <th></th>
                            <th>１食分</th>
                            <th></th>
                            <th>{{mathRound(totalServingData.energy)}}kcal</th>
                            <th>{{mathRound(totalServingData.protein)}}g</th>
                            <th>{{mathRound(totalServingData.lipid)}}g</th>
                            <th>{{mathRound(totalServingData.carbohydrate)}}g</th>
                            <th>{{mathRound(totalServingData.dietary_fiber)}}g</th>
                            <th>{{mathRound(totalServingData.salt)}}g</th>
                        </tr>
                        <tr class="total_result" v-show="totalData.energy" @click="openDetail(totalData, true, true)">
                            <th></th>
                            <th>全量</th>
                            <th></th>
                            <th>{{mathRound(totalData.energy)}}kcal</th>
                            <th>{{mathRound(totalData.protein)}}g</th>
                            <th>{{mathRound(totalData.lipid)}}g</th>
                            <th>{{mathRound(totalData.carbohydrate)}}g</th>
                            <th>{{mathRound(totalData.dietary_fiber)}}g</th>
                            <th>{{mathRound(totalData.salt)}}g</th>
                        </tr>
                    </template>
                </template>
            </v-table>
        </div>
        <div v-show="totalData.energy" @click="openRecipeEditor()" class="add-recipe">この食材でレシピを作成</div>
        <adsense type="1" />
        <weight-modal v-if="liveModal" :signal="signal" @food-decide="foodDecide" @close="liveModal = false"></weight-modal>
        <detail-modal v-if="liveModal2" :signal="signal" @close="liveModal2 = false"></detail-modal>
        <myfood-modal v-if="liveModal3" :signal="signal" @food-decide="foodDecide" @close="liveModal3 = false"></myfood-modal>
        <login-info-modal v-if="liveModal4" :signal="signal" @close="liveModal4 = false"></login-info-modal>
        <editor-modal v-if="liveModal5" :signal="signal" @research="research" @close="liveModal5 = false"></editor-modal>
        <msg-modal :signal="msgSignal"></msg-modal>
        <msg-toast :signal="toastMsg"></msg-toast>
    </div>
</template>
<script>
import weightModal from "./weight-modal.vue"
import detailModal from "./detail-modal.vue"
import myfoodModal from "./myfood-modal.vue"
import Editor from "../member/recipe/editor-modal.vue";
import MsgModal from "../../../components/msg-modal";
import MsgToast from "../../../components/msg-toast";
import loginInfoModal from "../../../components/login-info-modal.vue"
import Api from "../../../common/fetch-wrapper";
import VueSimpleSuggest from 'vue-simple-suggest'
import 'vue-simple-suggest/dist/styles.css'
import {Vuetable} from 'vuetable-2';
import {mapGetters} from "vuex";
import { USER_ARIES } from "../../../store/modules/user-store";
import { isEmpty } from '../../../common/com-func';
import Adsense from "../../../components/adsense.vue";

const TABLE_FIELDS = [
    {name:"delete", title:"",titleClass:"delete-col"},
    {name:"name", title:"食品名",titleClass:"name-col"},
    {name:"weight", title:"分量(g)",titleClass:"weight-col"},
    {name:"energy", title:"エネルギー",titleClass:"energy-col"},
    {name:"protein", title:"たんぱく質",titleClass:"protein-col"},
    {name:"lipid", title:"脂質",titleClass:"lipid-col"},
    {name:"carbohydrate", title:"炭水化物",titleClass:"carbohydrate-col"},
    {name:"dietary_fiber", title:"食物繊維",titleClass:"dietary_fiber-col"},
    {name:"salt", title:"塩分",titleClass:"salt-col"},
];

export default {
    components: {
        "vue-simple-suggest" : VueSimpleSuggest,
        "v-table" : Vuetable,
        "weight-modal" : weightModal,
        "detail-modal" : detailModal,
        "myfood-modal" : myfoodModal,
        "login-info-modal" : loginInfoModal,
        "editor-modal" : Editor,
        "msg-modal" : MsgModal,
        "msg-toast" : MsgToast,
        "adsense" : Adsense,
    },
    data :function() {
        return {
            isShow: false,
            preData: [],
            list: [],
            totalData: {
                energy:0,
                protein:0,
                lipid:0,
                dietary_fiber:0,
                carbohydrate:0,
                potassium:0,
                calcium:0,
                magnesium:0,
                iron:0,
                vitamin_a:0,
                vitamin_d:0,
                vitamin_e:0,
                vitamin_b1:0,
                vitamin_b2:0,
                vitamin_b6:0,
                vitamin_b12:0,
                vitamin_c:0,
                salt:0,
            },
            totalServingData: {
                energy:0,
                protein:0,
                lipid:0,
                dietary_fiber:0,
                carbohydrate:0,
                potassium:0,
                calcium:0,
                magnesium:0,
                iron:0,
                vitamin_a:0,
                vitamin_d:0,
                vitamin_e:0,
                vitamin_b1:0,
                vitamin_b2:0,
                vitamin_b6:0,
                vitamin_b12:0,
                vitamin_c:0,
                salt:0,
            },
            canSearch: false,
            canOpenDetail: true,
            servingCount: 1,
            keyword: "",
            fields : TABLE_FIELDS,
            liveModal : false,
            liveModal2 : false,
            liveModal3 : false,
            liveModal4 : false,
            liveModal5 : false,
            signal: {},
            msgSignal: {},
            toastMsg: {},
        }
    },
    computed: {
        ...mapGetters(USER_ARIES, ["logined"])
    },
    methods: {
        vueSimpleSuggestfilter (el, value) {
            return el.name.indexOf(value) !== -1 || el.name_kana.indexOf(value) !== -1 || el.display_name.indexOf(value) !== -1 || String(el.note).indexOf(value) !== -1 ? el : false
        },
        suggestionClick (data) {
            this.liveModal = true;
            this.$nextTick().then(() => {
                this.signal = Object.assign({},data);
            });
        },
        myfoodClick () {
            if(this.logined) {
                this.liveModal3 = true;
            } else {
                this.liveModal4 = true;
            }
            this.$nextTick().then(() => {
                this.signal = Object.assign({});
            });
        },
        reset:function() {
            this.servingCount = 1;
            this.list = [];
            this.totalResult(this.list);
        },
        openDetail:function(data, total = false, allTotal = false) {
            let detailData = "";
            if(total) {
                detailData = data;
                detailData.total = true;
            } else {
                detailData = data;
            }
            if(allTotal) {
                detailData.allTotal = true;
            }

            if(this.canOpenDetail) {
                this.liveModal2 = true;
                this.$nextTick().then(() => {
                    this.signal = Object.assign({},detailData);
                });
            }
            this.canOpenDetail = true;
        },
        deleteDetail:function(index,name) {
            this.msgSignal = {
                title: "確認",
                message: name + "を削除しますか？",
                callback:() => {
                    this.list.splice(index, 1);
                    this.totalResult(this.list);
                }
            };
        },
        foodDecide:function(foodId, weight, myfood = false, group = false, cookie = false, weightTypeValue = null) {
            const CountURL = myfood ? "/myfood/count-up" : "/food-master/count-up";
            const form = new FormData();
            form.append("id",foodId);
            Api.post(CountURL, form, (d) => {});

            const URL = myfood ? "/myfood/show/" : "/food-master/";
            Api.get(URL + foodId).then(x => {
                x.weight = weight;
                x.myfood_flg = myfood ? 1 : 0;
                Object.keys(x).forEach((key) => {
                    if(x[key] == "Tr" || x[key] == "-") {
                        x[key] = 0;
                    }
                });
                if(cookie) {
                    myfood = false;
                }
                if(!isEmpty(weightTypeValue)) {
                    x.weightTypeValue = weightTypeValue;
                }

                x.default_energy = myfood ? x.energy*100/weight : x.energy;
                x.default_protein = myfood ? x.protein*100/weight : x.protein;
                x.default_lipid = myfood ? x.lipid*100/weight : x.lipid;
                x.default_dietary_fiber = myfood ? x.dietary_fiber*100/weight : x.dietary_fiber;
                x.default_carbohydrate = myfood ? x.carbohydrate*100/weight : x.carbohydrate;
                x.default_potassium = myfood ? x.potassium*100/weight : x.potassium;
                x.default_calcium = myfood ? x.calcium*100/weight : x.calcium;
                x.default_magnesium = myfood ? x.magnesium*100/weight : x.magnesium;
                x.default_iron = myfood ? x.iron*100/weight : x.iron;
                x.default_vitamin_a = myfood ? x.vitamin_a*100/weight : x.vitamin_a;
                x.default_vitamin_d = myfood ? x.vitamin_d*100/weight : x.vitamin_d;
                x.default_vitamin_e = myfood ? x.vitamin_e*100/weight : x.vitamin_e;
                x.default_vitamin_b1 = myfood ? x.vitamin_b1*100/weight : x.vitamin_b1;
                x.default_vitamin_b2 = myfood ? x.vitamin_b2*100/weight : x.vitamin_b2;
                x.default_vitamin_b6 = myfood ? x.vitamin_b6*100/weight : x.vitamin_b6;
                x.default_vitamin_b12 = myfood ? x.vitamin_b12*100/weight : x.vitamin_b12;
                x.default_vitamin_c = myfood ? x.vitamin_c*100/weight : x.vitamin_c;
                x.default_salt = myfood ? x.salt*100/weight : x.salt;

                this.list.unshift(x);
                this.keyword = "";
                this.changeWeight(x, 0);
            });
        },
        totalResult:function(list) {
            this.totalData.energy = 0;
            this.totalData.protein = 0;
            this.totalData.lipid = 0;
            this.totalData.dietary_fiber = 0;
            this.totalData.carbohydrate = 0;
            this.totalData.potassium = 0;
            this.totalData.calcium = 0;
            this.totalData.magnesium = 0;
            this.totalData.iron = 0;
            this.totalData.vitamin_a = 0;
            this.totalData.vitamin_d = 0;
            this.totalData.vitamin_e = 0;
            this.totalData.vitamin_b1 = 0;
            this.totalData.vitamin_b2 = 0;
            this.totalData.vitamin_b6 = 0;
            this.totalData.vitamin_b12 = 0;
            this.totalData.vitamin_c = 0;
            this.totalData.salt = 0;
            for (let i=0; i<list.length; i++) {
                this.totalData.energy += isNaN(parseFloat(list[i].energy)) ? 0 : parseFloat(list[i].energy);
                this.totalData.protein += isNaN(parseFloat(list[i].protein)) ? 0 : parseFloat(list[i].protein);
                this.totalData.lipid += isNaN(parseFloat(list[i].lipid)) ? 0 : parseFloat(list[i].lipid);
                this.totalData.dietary_fiber += isNaN(parseFloat(list[i].dietary_fiber)) ? 0 : parseFloat(list[i].dietary_fiber);
                this.totalData.carbohydrate += isNaN(parseFloat(list[i].carbohydrate)) ? 0 : parseFloat(list[i].carbohydrate);
                this.totalData.potassium += isNaN(parseFloat(list[i].potassium)) ? 0 : parseFloat(list[i].potassium);
                this.totalData.calcium += isNaN(parseFloat(list[i].calcium)) ? 0 : parseFloat(list[i].calcium);
                this.totalData.magnesium += isNaN(parseFloat(list[i].magnesium)) ? 0 : parseFloat(list[i].magnesium);
                this.totalData.iron += isNaN(parseFloat(list[i].iron)) ? 0 : parseFloat(list[i].iron);
                this.totalData.vitamin_a += isNaN(parseFloat(list[i].vitamin_a)) ? 0 : parseFloat(list[i].vitamin_a);
                this.totalData.vitamin_d += isNaN(parseFloat(list[i].vitamin_d)) ? 0 : parseFloat(list[i].vitamin_d);
                this.totalData.vitamin_e += isNaN(parseFloat(list[i].vitamin_e)) ? 0 : parseFloat(list[i].vitamin_e);
                this.totalData.vitamin_b1 += isNaN(parseFloat(list[i].vitamin_b1)) ? 0 : parseFloat(list[i].vitamin_b1);
                this.totalData.vitamin_b2 += isNaN(parseFloat(list[i].vitamin_b2)) ? 0 : parseFloat(list[i].vitamin_b2);
                this.totalData.vitamin_b6 += isNaN(parseFloat(list[i].vitamin_b6)) ? 0 : parseFloat(list[i].vitamin_b6);
                this.totalData.vitamin_b12 += isNaN(parseFloat(list[i].vitamin_b12)) ? 0 : parseFloat(list[i].vitamin_b12);
                this.totalData.vitamin_c += isNaN(parseFloat(list[i].vitamin_c)) ? 0 : parseFloat(list[i].vitamin_c);
                this.totalData.salt += isNaN(parseFloat(list[i].salt)) ? 0 : parseFloat(list[i].salt);
            }
            this.servingResult();
        },
        servingResult:function() {
            if (this.servingCount == "" || this.servingCount == 0) {
                this.totalServingData.energy = 0;
                this.totalServingData.protein = 0;
                this.totalServingData.lipid = 0;
                this.totalServingData.dietary_fiber = 0;
                this.totalServingData.carbohydrate = 0;
                this.totalServingData.potassium = 0;
                this.totalServingData.calcium = 0;
                this.totalServingData.magnesium = 0;
                this.totalServingData.iron = 0;
                this.totalServingData.vitamin_a = 0;
                this.totalServingData.vitamin_d = 0;
                this.totalServingData.vitamin_e = 0;
                this.totalServingData.vitamin_b1 = 0;
                this.totalServingData.vitamin_b2 = 0;
                this.totalServingData.vitamin_b6 = 0;
                this.totalServingData.vitamin_b12 = 0;
                this.totalServingData.vitamin_c = 0;
                this.totalServingData.salt = 0;
            } else {
                this.totalServingData.energy = this.mathRound(this.totalData.energy / this.servingCount);
                this.totalServingData.protein = this.mathRound(this.totalData.protein / this.servingCount);
                this.totalServingData.lipid = this.mathRound(this.totalData.lipid / this.servingCount);
                this.totalServingData.dietary_fiber = this.mathRound(this.totalData.dietary_fiber / this.servingCount);
                this.totalServingData.carbohydrate = this.mathRound(this.totalData.carbohydrate / this.servingCount);
                this.totalServingData.potassium = this.mathRound(this.totalData.potassium / this.servingCount);
                this.totalServingData.calcium = this.mathRound(this.totalData.calcium / this.servingCount);
                this.totalServingData.magnesium = this.mathRound(this.totalData.magnesium / this.servingCount);
                this.totalServingData.iron = this.mathRound(this.totalData.iron / this.servingCount);
                this.totalServingData.vitamin_a = this.mathRound(this.totalData.vitamin_a / this.servingCount);
                this.totalServingData.vitamin_d = this.mathRound(this.totalData.vitamin_d / this.servingCount);
                this.totalServingData.vitamin_e = this.mathRound(this.totalData.vitamin_e / this.servingCount);
                this.totalServingData.vitamin_b1 = this.mathRound(this.totalData.vitamin_b1 / this.servingCount);
                this.totalServingData.vitamin_b2 = this.mathRound(this.totalData.vitamin_b2 / this.servingCount);
                this.totalServingData.vitamin_b6 = this.mathRound(this.totalData.vitamin_b6 / this.servingCount);
                this.totalServingData.vitamin_b12 = this.mathRound(this.totalData.vitamin_b12 / this.servingCount);
                this.totalServingData.vitamin_c = this.mathRound(this.totalData.vitamin_c / this.servingCount);
                this.totalServingData.salt = this.mathRound(this.totalData.salt / this.servingCount);
            }
            this.setCookie();
        },
        mathRound:function(data) {
            return Math.round(data * 10) / 10;
        },
        changeWeightType:function(data, weightTypeValue, index) {
            let one_weight = weightTypeValue.one_weight;
            if(one_weight.includes('/')) {
                const cut1 = one_weight.substr(0, one_weight.indexOf('/'));
                const cut2 = one_weight.substr(one_weight.indexOf('/') + 1);
                one_weight = cut1 / cut2;
            }
            if(isNaN(one_weight) || one_weight == "Infinity") {
                one_weight = 0;
            }
            let weight = parseFloat(one_weight) * parseFloat(weightTypeValue.weight);
            weight = isNaN(weight) ? 0 : weight;
            weight = Math.round(weight * 10) / 10;
            this.changeWeight(data, index, weight, true);
        },
        changeWeight:function(data, index, w, weightType = false) {
            let weight = weightType ? w : data.weight;
            if(weightType) {
                this.list[index].weight = w;
            }

            let energy = data.default_energy * weight / 100;
            let protein = data.default_protein * weight / 100;
            let lipid = data.default_lipid * weight / 100;
            let dietary_fiber = data.default_dietary_fiber * weight / 100;
            let carbohydrate = data.default_carbohydrate * weight / 100;
            let potassium = data.default_potassium * weight / 100;
            let calcium = data.default_calcium * weight / 100;
            let magnesium = data.default_magnesium * weight / 100;
            let iron = data.default_iron * weight / 100;
            let vitamin_a = data.default_vitamin_a * weight / 100;
            let vitamin_d = data.default_vitamin_d * weight / 100;
            let vitamin_e = data.default_vitamin_e * weight / 100;
            let vitamin_b1 = data.default_vitamin_b1 * weight / 100;
            let vitamin_b2 = data.default_vitamin_b2 * weight / 100;
            let vitamin_b6 = data.default_vitamin_b6 * weight / 100;
            let vitamin_b12 = data.default_vitamin_b12 * weight / 100;
            let vitamin_c = data.default_vitamin_c * weight / 100;
            let salt = data.default_salt * weight / 100;
            this.list[index].energy = Math.round(energy * 10) / 10;
            this.list[index].protein = Math.round(protein * 10) / 10;
            this.list[index].lipid = Math.round(lipid * 10) / 10;
            this.list[index].dietary_fiber = Math.round(dietary_fiber * 10) / 10;
            this.list[index].carbohydrate = Math.round(carbohydrate * 10) / 10;
            this.list[index].potassium = Math.round(potassium * 10) / 10;
            this.list[index].calcium = Math.round(calcium * 10) / 10;
            this.list[index].magnesium = Math.round(magnesium * 10) / 10;
            this.list[index].iron = Math.round(iron * 10) / 10;
            this.list[index].vitamin_a = Math.round(vitamin_a * 10) / 10;
            this.list[index].vitamin_d = Math.round(vitamin_d * 10) / 10;
            this.list[index].vitamin_e = Math.round(vitamin_e * 10) / 10;
            this.list[index].vitamin_b1 = Math.round(vitamin_b1 * 10) / 10;
            this.list[index].vitamin_b2 = Math.round(vitamin_b2 * 10) / 10;
            this.list[index].vitamin_b6 = Math.round(vitamin_b6 * 10) / 10;
            this.list[index].vitamin_b12 = Math.round(vitamin_b12 * 10) / 10;
            this.list[index].vitamin_c = Math.round(vitamin_c * 10) / 10;
            this.list[index].salt = Math.round(salt * 10) / 10;
            this.totalResult(this.list);
        },
        noEvent:function() {
            this.canOpenDetail = false;
        },
        openRecipeEditor:function() {
            const data = {}
            if(this.logined) {
                const energy = this.totalServingData.energy;
                const protein = this.totalServingData.protein;
                const lipid = this.totalServingData.lipid;
                const dietary_fiber = this.totalServingData.dietary_fiber;
                const carbohydrate = this.totalServingData.carbohydrate;
                const potassium = this.totalServingData.potassium;
                const calcium = this.totalServingData.calcium;
                const magnesium = this.totalServingData.magnesium;
                const iron = this.totalServingData.iron;
                const vitamin_a = this.totalServingData.vitamin_a;
                const vitamin_d = this.totalServingData.vitamin_d;
                const vitamin_e = this.totalServingData.vitamin_e;
                const vitamin_b1 = this.totalServingData.vitamin_b1;
                const vitamin_b2 = this.totalServingData.vitamin_b2;
                const vitamin_b6 = this.totalServingData.vitamin_b6;
                const vitamin_b12 = this.totalServingData.vitamin_b12;
                const vitamin_c = this.totalServingData.vitamin_c;
                const salt = this.totalServingData.salt;

                data.totalServingData = {};
                data.totalServingData.energy = energy;
                data.totalServingData.protein = protein;
                data.totalServingData.lipid = lipid;
                data.totalServingData.dietary_fiber = dietary_fiber;
                data.totalServingData.carbohydrate = carbohydrate;
                data.totalServingData.potassium = potassium;
                data.totalServingData.calcium = calcium;
                data.totalServingData.magnesium = magnesium;
                data.totalServingData.iron = iron;
                data.totalServingData.vitamin_a = vitamin_a;
                data.totalServingData.vitamin_d = vitamin_d;
                data.totalServingData.vitamin_e = vitamin_e;
                data.totalServingData.vitamin_b1 = vitamin_b1;
                data.totalServingData.vitamin_b2 = vitamin_b2;
                data.totalServingData.vitamin_b6 = vitamin_b6;
                data.totalServingData.vitamin_b12 = vitamin_b12;
                data.totalServingData.vitamin_c = vitamin_c;
                data.totalServingData.salt = salt;

                data.calculator = this.list;
                data.serving = this.servingCount;
                this.liveModal5 = true;
            } else {
                this.liveModal4 = true;
            }
            this.$nextTick().then(() => {
                this.signal = Object.assign({},data);
            });
        },
        research:function(register) {
            if(register) {
                this.toastMsg = {message:"レシピを保存しました。"};
            } else {
                this.toastMsg = {message:"レシピを下書き保存しました。"};
            }
        },
        setCookie:function() {
            let list = [];
            this.list.forEach(e => {
                list.unshift({
                    "id": e.id,
                    "weight": e.weight,
                    "weightTypeValue": e.weightTypeValue,
                    "myfood_flg": e.myfood_flg ? true : false,
                });
            });
            this.$cookies.set('servingCount', this.servingCount, 60 * 60 * 24);
            this.$cookies.set('foodList', list, 60 * 60 * 24);
        },
    },
    mounted:function() {
        this.servingCount = this.$cookies.get('servingCount');
        if(!this.servingCount) {
            this.servingCount = 1;
        }

        if($cookies.isKey('foodList')) {
            this.$cookies.get('foodList').forEach(e => {
                this.foodDecide(e.id, e.weight, e.myfood_flg, false, true, e.weightTypeValue);
            });
        }
        Api.get("/food-master/pre-search").then(x => {
            this.preData = x;
            this.canSearch = true;
        });

        this.isShow = true;
    },
};
</script>