<style lang="scss" scoped>
@import "../../sass/variables";
.menu-block {
    position: relative;
    width: 280px;
    margin: 0 0 0 20px;
    padding: 2rem 0 0 0;
    .menu-ad {
        padding: 0
    }
    .user-menu {
        margin: 0 0 1rem;
        .user-image {
            position: relative;
            display: flex;
            align-items: center;
            &.premium {
                padding: 5rem 0 0 1rem;
            }
            .banner-bg {
                width: 100%;
                aspect-ratio: 3/1;
                position: absolute;
                top: 0;
                left: 0;
                z-index: -1;
                &::after {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    aspect-ratio: 3/1;
                    background-image: linear-gradient(180deg, transparent 0 60%, #f8fafc 100%);
                }
                .banner {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
            .icon {
                width: 45px;
                height: 45px;
                object-fit: cover;
                border-radius: 50%;
            }
            h5 {
                padding: 0;
                margin: 0;
            }
        }
        h5 {
            color: $brown-font;
            padding: 0 0 0 2rem;
            a {
                text-decoration: none;
                color: $brown-font;
            }
        }
        p {
            margin: 1rem 0 0.2rem 1rem;
        }
    }
    .site-menu {
        h6 {
            padding: 0.5rem 1rem;
            margin: 0;
            color: $brown-font;
            background-color: #fff4dd;
        }
        >a {
            display: flex;
            align-items: center;
            text-decoration: none;
            color: $black-font;
            padding: 0.8rem 1rem;
            font-size: 16px;
            transition: 0.3s;
            &::after {
                content: "＞";
                position: absolute;
                right: 1rem;
                font-size: 12px;
            }
            &:hover {
                background-color: #eee;
            }
        }
        .sns-icons {
            display: flex;
            justify-content: initial;
            margin: 0.6rem 0 0 1rem;
        }
    }
    button {
        display: block;
        width: 80%;
        background-color: $main-color-red;
        color: #fff;
        border-radius: 20px;
        margin: 0 auto 1rem;
        padding: 0.3rem;
        border: none;
        transition: 0.3s;
        &.logout {
            background-color: #888;
            margin: 4rem auto 0;
        }
        &:hover {
            opacity: 0.6;
        }
    }
    .column {
        margin: 0.5rem 0 0;
        padding: 0 1rem;
        .image-box {
            width: 100%;
            img {
                width: 100%;
                object-fit: cover;
                aspect-ratio: 5 / 3;
                transition: 0.3s;
            }
        }
        .title {
            color: $black-font;
            font-size: 16px;
            overflow: hidden;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
        }
        img, .title {
            &:hover {
                cursor: pointer;
                opacity: 0.8;
            }
        }
    }
}
@media screen and (max-width: 1000px) {
    .menu-block {display: none;}
}
</style>
<template>
    <div class="menu-block" @click="click()">
        <adsense type="3" class="menu-ad" />
        <div class="user-menu">
            <div v-if="logined" class="user-image" :class="{'premium':userPremium}">
                <div v-if="userBanner && userPremium" class="banner-bg">
                    <img class="banner" :src="`https://healtheat.s3.ap-northeast-1.amazonaws.com${userBanner}`" alt="">
                </div>
                <img v-if="userIcon" class="icon" :src="`https://healtheat.s3.ap-northeast-1.amazonaws.com${userIcon}`" alt="">
                <img v-if="!userIcon" class="icon" :src="NoImgUrl" alt="">
                <h5><router-link :to="`/member/${profileId}`">{{userName}}</router-link></h5>
            </div>
            <h5 v-if="!logined">{{userName}}</h5>
            <div v-if="!logined">
                <p>会員登録してる方</p>
                <button @click="login">ログイン</button>
            </div>
            <div v-if="!logined">
                <p>まだ会員登録していない方</p>
                <button @click="register">新規会員登録（無料）</button>
            </div>
        </div>
        <div class="site-menu">
            <h6>サイトメニュー</h6>
            <router-link to="/" class="recipe">レシピ</router-link>
            <router-link to="/recipe/ranking" class="ranking">人気のレシピ</router-link>
            <router-link to="/column" class="recipe-summary">コラム</router-link>
            <router-link to="/calculator" class="calculator">栄養価計算</router-link>
            <router-link to="/myfood" class="myfood">みんなのMY食品</router-link>
            <router-link v-if="logined" :to="`/member/${profileId}`" class="profile">プロフィール</router-link>

            <h6 v-if="logined">ユーザーメニュー</h6>
            <router-link v-if="logined" to="/member/info" class="mypage">マイページ</router-link>
            <router-link v-if="logined" to="/member/recipe" class="myrecipe">レシピ管理</router-link>
            <router-link to="/myfood/custom" class="myfood">MY食品管理</router-link>

            <h6>その他</h6>
            <router-link to="/terms" class="terms">利用規約</router-link>
            <router-link to="/privacy-policy" class="privacy">プライバシーポリシー</router-link>
            <router-link to="/contact" class="contact">お問い合わせ</router-link>

            <h6>公式SNS</h6>
            <sns-link class="sns-icons"></sns-link>
        </div>

        <button v-if="logined" @click="logout" class="logout">ログアウト</button>
        <adsense type="3" />
        <div class="site-menu">
            <h6>新着コラム</h6>
            <div class="column" v-for="(column,i) in columnList" :key="i">
                <div class="image-box" @click="columnClick(column)">
                    <img v-if="column.image" :src="`https://healtheat.s3.ap-northeast-1.amazonaws.com${column.image}`" alt="">
                    <img v-if="!column.image" :src="NoImgUrl" alt="">
                </div>
                <div class="text-box">
                    <p @click="columnClick(column)" class="title">{{column.title}}</p>
                </div>
            </div>
            <div class="more-recipe">
                <router-link to="/column">コラムをもっと見る</router-link>
            </div>
        </div>
        <adsense type="3" />
    </div>
</template>
<script>
import VueRouter from 'vue-router';
import {mapGetters} from "vuex";
import { USER_ARIES } from "../store/modules/user-store";
import snsLink from "./sns-link.vue";
import {NO_IMG_URL} from "../common/constants";
import Adsense from "./adsense.vue";
import Api from "../common/fetch-wrapper";

export default {
    components: {
        "sns-link" : snsLink,
        "adsense" : Adsense,
    },
    data:function() {
        return {
            open: false,
            NoImgUrl: NO_IMG_URL,
            columnList: [],
        };
    },
    computed: {
        ...mapGetters(USER_ARIES,["logined","userName","profileId","userIcon","userBanner","userPremium"]),
    },
    methods: {
        bkClick:function() {
            this.open = false;
        },
        click:function() {
            this.open = false;
        },
        login:function() {
            this.$router.push({path:"/login"}).catch(err => {});
        },
        register:function() {
            this.$router.push({path:"/register"}).catch(err => {});
        },
        logout:function() {
            location.href = "/member/logout";
        },
        getColumn:function() {
            Api.get("/column/new").then(x => {
                this.columnList = x;
            });
        },
        columnClick:function(data) {
            const { isNavigationFailure, NavigationFailureType } = VueRouter;
            if(data.type == 1){
                this.$router.push({path:`/recipe-summary/${data.id}`}).catch(err => {
                    if(isNavigationFailure(err, NavigationFailureType.duplicated)) {
                        window.scrollTo({
                            top: 0,
                            behavior: "instant",
                        });
                    }
                });
            } else {
                this.$router.push({path:`/column/${data.id}`}).catch(err => {
                    if(isNavigationFailure(err, NavigationFailureType.duplicated)) {
                        window.scrollTo({
                            top: 0,
                            behavior: "instant",
                        });
                    }
                });
            }
        },
    },
    mounted:function() {
        this.getColumn();
    }
}
</script>