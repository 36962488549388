<style lang="scss" scoped>
@import "../../../../sass/variables";
.title_wrap {
    span {
        &::before {
            content: '\f02d';
            font-family: "Font Awesome 5 Free";
            font-weight: bold;
            font-size: 1.6rem;
            margin: 0 0.3rem 0 0;
        }
    }
}
.summary_content {
    h1 {
        font-size: 1.2rem;
        padding: 0 0.5rem;
    }
    h2 {
        font-size: 1.1rem;
        font-weight: bold;
        padding: 0 0 0.4rem;
        margin: 0 0 1rem;
        border-bottom: solid 3px $main-color-orange;
        transition: 0.3s;
        &:hover {
            cursor: pointer;
            opacity: 0.8;
        }
        &.end_body {
            margin: 0 0.5rem;
        }
    }
    .image {
        width: 100%;
        img {
            width: 100%;
            object-fit: cover;
            aspect-ratio: 1 / 1;
        }
    }
    .sns-share {
        margin: 1rem 0 0;
    }
    > p {
        padding: 0.5rem;
        white-space: pre-wrap;
        line-height: 1.6rem;
    }
    .recipe_box {
        padding: 0 0.5rem;
        .recipe {
            margin: 0 0 3rem;
            > p {
                padding: 0.5rem;
                white-space: pre-wrap;
                line-height: 1.6rem;
            }
        }
        .recipe_image {
            width: 100%;
            img {
                width: 95%;
                object-fit: cover;
                aspect-ratio: 1 / 1;
                margin: 0 2.5%;
                transition: 0.3s;
                &:hover {
                    cursor: pointer;
                    opacity: 0.8;
                }
            }
        }
        .info {
            display: flex;
            margin: 0 0.2rem;
            div {
                position: relative;
                width: 50%;
                text-align: center;
                margin: 0 0.1rem;
                padding: 0.2rem 0 0.4rem;
                border-radius: 4px;
                line-height: 1.2rem;
                p {
                    margin: 0;
                    &::before {
                        font-family: "Font Awesome 5 Free";
                        font-weight: bold;
                        font-size: 1.2rem;
                        display: inline-block;
                        margin: 0 0.3rem 0 0;
                        transform: translateY(2px);
                    }
                }
                span {
                    font-size: 0.8rem;
                }
                .one-serving {
                    position: absolute;
                    right: 0.2rem;
                    bottom: 0.2rem;
                    font-size: 0.6rem;
                }
            }
            .cooking-time {
                background-color: #a7d9ab;
                color: #344435;
                p {
                    &::before {
                        content: '\f017';
                    }
                }
            }
            .energy {
                background-color: #f7d27f;
                color: #6d411c;
                p {
                    &::before {
                        content: '\f06d';
                    }
                }
            }
        }
        .recipe-info {
            margin: 0.5rem 0 0;
            ul {
                list-style: none;
                padding: 0;
                li {
                    padding: 0.5rem 0.5rem 0;
                    border-bottom: 1px solid $border-color;
                    &.is-group {
                        border-bottom: none;
                    }
                    .list {
                        display: flex;
                        justify-content: space-between;
                        align-items: baseline;
                        transition: 0.3s;
                        h3 {
                            max-width: 65%;
                            font-size: 1rem;
                            margin: 0 1rem 0.5rem 0;
                        }
                        .sub-weight {
                            font-size: 0.8rem;
                            margin: 0 0 0 0.2rem;
                        }
                    }
                    .group-food-wrap {
                        p {
                            font-weight: bold;
                            margin: 0;
                            &::before {
                                content: "";
                                display: inline-block;
                                height: 12px;
                                width: 12px;
                                margin: 0 0.2rem 0 0;
                                background-color: #000;
                            }
                        }
                        .group-food {
                            padding: 0.5rem 0 0 0.5rem;
                            border-bottom: 1px solid $border-color;
                        }
                    }
                }
            }
        }
        .member {
            text-decoration: none;
            color: $black-font;
            transition: 0.3s;
            .member-info {
                position: relative;
                margin: 1rem 0.5rem 2rem;
                padding: 0.5rem;
                background-color: #fff2dc;
                border-radius: 4px;
                .member-title {
                    font-size: 1.1rem;
                    margin: 0 0 0.5rem;
                }
                .member-content-wrap {
                    display: flex;
                    img {
                        width: 50px;
                        height: 50px;
                        object-fit: cover;
                        border-radius: 50%;
                    }
                    .member-content {
                        display: flex;
                        align-items: center;
                        .member-text {
                            margin: 0 1.5rem 0 0.5rem;
                            h5 {
                                font-size: 1rem;
                                font-weight: bold;
                            }
                            p {
                                font-size: 0.8rem;
                                line-height: 1rem;
                                white-space: pre-wrap;
                            }
                        }
                        .arrow-right {
                            position: absolute;
                            top: 50%;
                            right: 1rem;
                            display: block;
                            min-width: 12px;
                            height: 12px;
                            border: 0px;
                            border-top: solid 1px #000;
                            border-right: solid 1px #000;
                            transform: rotate(45deg);
                        }
                    }
                }
            }
            &:hover {
                cursor: pointer;
                opacity: 0.8;
            }
        }
    }
}
@media screen and (min-width: 1000px) {
    .summary_content {
        padding: 1rem 2rem 4rem;
        .image img {
            aspect-ratio: 5 / 3;
        }
        .recipe_box .recipe_image img {
            width: 60%;
            margin: 0;
        }
    }
}
</style>
<template>
<div class="user_contents">
    <div class="title_wrap">
        <span>コラム</span>
    </div>
    <search-component></search-component>
    <div class="summary_content" v-if="showDetail">
        <h1>{{ recipeSummary.title }}</h1>
        <div class="image">
            <img v-if="recipeSummary.image" class="thumbnail" :src="`https://healtheat.s3.ap-northeast-1.amazonaws.com${recipeSummary.image}`" :alt="`${recipeSummary.title}の画像`">
            <img v-if="!recipeSummary.image" class="thumbnail" :src="NoImgUrl" :alt="`${recipeSummary.title}の画像`">
        </div>
        <div class="sns-share">
            <social-sharing
                :url="`https://healtheat.jp/recipe-summary/${recipeSummary.id}\n\n`"
                :title="`${recipeSummary.title}\n`"
                twitter-user="healtheat_jp"
                inline-template
            >
                <network class="button twitter" network="twitter">ポスト</network>
            </social-sharing>
            <social-sharing
                :url="`https://healtheat.jp/recipe-summary/${recipeSummary.id}`"
                :title="recipeSummary.title"
                :description="recipeSummary.title"
                inline-template
            >
                <network class="button line" network="line">LINE</network>
            </social-sharing>
            <social-sharing
                :url="`https://healtheat.jp/recipe-summary/${recipeSummary.id}`"
                :title="recipeSummary.title"
                :description="recipeSummary.body"
                inline-template
            >
                <network class="button facebook" network="facebook">シェア</network>
            </social-sharing>
            <social-sharing
                :url="`https://healtheat.jp/recipe-summary/${recipeSummary.id}`"
                :title="recipeSummary.title"
                :description="recipeSummary.body"
                :media="`https://healtheat.s3.ap-northeast-1.amazonaws.com${recipeSummary.image}`"
                inline-template
            >
                <network class="button pinterest" network="pinterest">保存</network>
            </social-sharing>
        </div>
        <p>{{ recipeSummary.body }}</p>
        <adsense type="1" />
        <div class="recipe_box">
            <div class="recipe" v-for="(recipe_relation,i) in recipeSummary.recipe_relation" :key="i">
                <h2 @click="clickRecipe(recipe_relation.recipe_id)">{{ i+1 }}.{{ recipe_relation.recipe.name }}</h2>
                <div class="recipe_image">
                    <img @click="clickRecipe(recipe_relation.recipe_id)" v-if="recipe_relation.recipe.thumbnail" class="thumbnail" :src="`https://healtheat.s3.ap-northeast-1.amazonaws.com${recipe_relation.recipe.thumbnail}`" :alt="`${recipe_relation.recipe.name}の画像`">
                    <img @click="clickRecipe(recipe_relation.recipe_id)" v-if="!recipe_relation.recipe.thumbnail" class="thumbnail" :src="NoImgUrl" :alt="`${recipe_relation.recipe.name}の画像`">
                </div>
                <p>{{ recipe_relation.note }}</p>
                <div class="info">
                    <div class="cooking-time">
                        <p>調理時間</p>
                        <span>{{recipe_relation.recipe.cooking_time}}分</span>
                    </div>
                    <div class="energy">
                        <p>エネルギー</p>
                        <span>{{recipe_relation.recipe.energy}}kcal</span>
                        <span class="one-serving">※1食分</span>
                    </div>
                </div>

                <div class="recipe-info">
                    <p class="recipe-title">材料<span>（{{recipe_relation.recipe.serving}}人分）</span></p>
                    <ul>
                        <li v-for="(food,i) in recipe_relation.recipe.recipe_food" :key="i" :class="{'is-group' : food.food_group.length !== 0 }">
                            <div class="list" v-if="food.recipe_food_master">
                                <h3>{{food.recipe_food_master.recipe_name}}</h3>
                                <div>
                                    <div v-if="food.weight_type && food.weight_type.name != 'g'">
                                        <span v-if="food.weight_type.name == '大さじ' || food.weight_type.name == '小さじ'">{{food.weight_type.name}}</span>
                                        <span>{{food.one_weight}}</span>
                                        <span v-if="food.weight_type.name != '大さじ' && food.weight_type.name != '小さじ'">{{food.weight_type.name}}</span>
                                        <span class="sub-weight">({{food.weight}}g)</span>
                                    </div>
                                    <span v-if="!food.weight_type || food.weight_type.name == 'g'">{{food.weight}}g</span>
                                </div>
                            </div>
                            <div class="list" v-if="food.recipe_my_food">
                                <h3>{{food.recipe_my_food.name}}</h3>
                                <span>{{food.weight}}g</span>
                            </div>

                            <div v-if="food.food_group.length" class="group-food-wrap">
                                <p>{{food.group_name}}</p>
                                <div class="group-food" v-for="(gfood,i2) in food.food_group" :key="i2">
                                    <div class="list" v-if="gfood.recipe_food_master">
                                        <h3>{{gfood.recipe_food_master.recipe_name}}</h3>
                                        <div>
                                            <div v-if="gfood.weight_type && gfood.weight_type.name != 'g'">
                                                <span v-if="gfood.weight_type.name == '大さじ' || gfood.weight_type.name == '小さじ'">{{gfood.weight_type.name}}</span>
                                                <span>{{gfood.one_weight}}</span>
                                                <span v-if="gfood.weight_type.name != '大さじ' && gfood.weight_type.name != '小さじ'">{{gfood.weight_type.name}}</span>
                                                <span class="sub-weight">({{gfood.weight}}g)</span>
                                            </div>
                                            <span v-if="!gfood.weight_type || gfood.weight_type.name == 'g'">{{gfood.weight}}g</span>
                                        </div>
                                    </div>
                                    <div class="list" v-if="gfood.recipe_my_food">
                                        <h3>{{gfood.recipe_my_food.name}}</h3>
                                        <span>{{gfood.weight}}g</span>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <div class="more-recipe">
                        <router-link :to="`/recipe/${recipe_relation.recipe_id}`">作り方の詳細はこちら</router-link>
                    </div>
                </div>

                <router-link class="member" :to="`/member/${recipe_relation.recipe.member.profile_id}`">
                    <div class="member-info">
                        <p class="member-title">投稿者</p>
                        <div class="member-content-wrap">
                            <img v-if="recipe_relation.recipe.member.image_icon" class="icon" :src="`https://healtheat.s3.ap-northeast-1.amazonaws.com${recipe_relation.recipe.member.image_icon}`" alt="">
                            <img v-if="!recipe_relation.recipe.member.image_icon" class="icon" :src="NoImgUrl" alt="">
                            <div class="member-content">
                                <div class="member-text">
                                    <h5>{{recipe_relation.recipe.member.name}}</h5>
                                    <p>{{recipe_relation.recipe.note}}</p>
                                </div>
                                <span class="arrow-right"></span>
                            </div>
                        </div>
                    </div>
                </router-link>
                <adsense type="1" />
            </div>
        </div>
        <h3 class="end_body">まとめ</h3>
        <p>{{ recipeSummary.end_body }}</p>
        <div class="url_link" v-html="recipeSummary.url_link"></div>
        <adsense type="1" />
        <div class="column-new">
            <h2 class="recipe-title">新着コラム</h2>
            <div class="column row" v-for="(column,i) in columnList" :key="i">
                <div class="flex">
                    <div @click="columnClick(column)">
                        <img v-if="column.image" :src="`https://healtheat.s3.ap-northeast-1.amazonaws.com${column.image}`" :alt="`${column.title}の画像`">
                        <img v-if="!column.image" :src="NoImgUrl" :alt="`${column.title}の画像`">
                    </div>
                    <div>
                        <h3 @click="columnClick(column)" class="title">{{column.title}}</h3>
                        <p @click="columnClick(column)">{{column.body}}</p>
                    </div>
                </div>
            </div>
            <div class="more-recipe">
                <router-link to="/column">コラムをもっと見る</router-link>
            </div>
        </div>
        <adsense type="1" />
    </div>
</div>
</template>
<script>
import VueRouter from 'vue-router';
import searchComponent from "../recipe/search-component.vue"
import Api from "../../../common/fetch-wrapper";
import {NO_IMG_URL} from "../../../common/constants"
import Adsense from "../../../components/adsense.vue";

export default {
    props: {
        recipeSummaryId: "",
    },
    components: {
        "search-component" : searchComponent,
        "adsense" : Adsense,
    },
    data() {
        return {
            showDetail: false,
            recipeSummary: {},
            columnList: [],
            NoImgUrl: NO_IMG_URL,
        }
    },
    watch: {
        $route () {
            this.showDetail = false,
            this.getSummary();
        }
    },
    methods: {
        getSummary:function() {
            Api.get("/recipe-summary/detail/" + this.recipeSummaryId).then(x => {
                // レシピが公開されてない場合はリダイレクト
                if(x.status !== 2) {
                    return location.href = "/not-found";
                }
                this.recipeSummary = x;

                document.title = `${this.recipeSummary.title} | ヘルシート`;
                document.querySelector("meta[name='description']").setAttribute('content', this.recipeSummary.body);
                document.querySelector("meta[name='thumbnail']").setAttribute('content', `https://healtheat.s3.ap-northeast-1.amazonaws.com${this.recipeSummary.image}`);
                document.querySelector("meta[property='og:site_name']").setAttribute('content', this.recipeSummary.title);
                document.querySelector("meta[property='og:title']").setAttribute('content', this.recipeSummary.title);
                document.querySelector("meta[property='og:description']").setAttribute('content', this.recipeSummary.body);
                document.querySelector("meta[property='og:image']").setAttribute('content', `https://healtheat.s3.ap-northeast-1.amazonaws.com${this.recipeSummary.image}`);

                Api.get("/recipe-summary/view/" + this.recipeSummaryId);
                this.showDetail = true;
            });
        },
        clickRecipe:function(recipe_id) {
            this.$router.push({path:`/recipe/${recipe_id}`}).catch(err => {});
        },
        getColumnList:function() {
            Api.get("/column/new").then(x => {
                this.columnList = x;
            });
        },
        columnClick:function(data) {
            const { isNavigationFailure, NavigationFailureType } = VueRouter;
            if(data.type == 1){
                this.$router.push({path:`/recipe-summary/${data.id}`}).catch(err => {
                    if(isNavigationFailure(err, NavigationFailureType.duplicated)) {
                        window.scrollTo({
                            top: 0,
                            behavior: "instant",
                        });
                    }
                });
            } else {
                this.$router.push({path:`/column/${data.id}`}).catch(err => {
                    if(isNavigationFailure(err, NavigationFailureType.duplicated)) {
                        window.scrollTo({
                            top: 0,
                            behavior: "instant",
                        });
                    }
                });
            }
        },
    },
    mounted:function() {
        this.getSummary();
        this.getColumnList();
    },
}
</script>