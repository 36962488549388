<style lang="scss" scoped>
@import "../../../../sass/variables";
.recipe-menu {
    background-color: #fff;
}
.search_box {
    width: 90%;
    padding: 0.5rem 0;
    margin: 0 auto;
    .free-word {
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: 0;
        input {
            width: 87%;
            padding: 0 0.6rem;
            height: 2rem;
            border-radius: 8px;
            border: 2px solid $main-color-orange;
            transition: 0.2s;
            &:focus {
                outline: 0;
                box-shadow: 0 0 0 3px #f2e8d6;
            }
        }
        .search-btn {
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 8px;
            transition: 0.3s;
            margin: 0 0 0 0.1rem;
            &:hover {
                cursor: pointer;
                box-shadow: 0 0 0 3px #f2e8d6;
            }
            &.icon{
                width: 12%;
                color: #fff;
                background: $main-color-orange;
                border: initial;
                &::before {
                    content: '\f002';
                    font-family: "Font Awesome 5 Free";
                    font-weight: bold;
                    font-size: 1rem;
                }
            }
            &.detail {
                width: 30%;
                background: $main-color-light-green;
                border: 1px solid $main-color-green;
                &:hover {
                    box-shadow: 0 0 0 3px #d6f2de;
                }
            }
        }
    }
}
.menu-list {
    ul {
        list-style: none;
        display: flex;
        justify-content: space-around;
        padding: 0;
        margin: 0.5rem 0;
        box-shadow: 1px 2px 2px $border-color;
        a {
            width: 100%;
            font-size: 0.8rem;
            text-align: center;
            text-decoration: none;
            color: $black-font;
            background-color: #fff;
            padding: 0 0 1rem;
            &:not(:first-of-type) {
                li {
                    border-left: 1px solid $border-color;
                }
            }
        }
    }
}
@media screen and (min-width: 1000px) {
    .menu-list {
        ul {
            box-shadow: 0px 5px 5px -4px $border-color;
        }
    }
}
</style>
<template>
<div class="recipe-menu">
    <div class="search_box">
        <div class="free-word">
            <input type="text" v-on:keypress.enter="search" v-model="form.name" placeholder="料理名・食材名など">
            <span class="search-btn icon" @click="search"></span>
            <span class="search-btn detail" @click="openDetail">詳細検索</span>
        </div>
    </div>
    <div class="menu-list">
        <ul>
            <router-link to="/recipe/category"><li>カテゴリ一覧</li></router-link>
            <router-link to="/recipe/favorite"><li>お気に入りレシピ</li></router-link>
            <router-link to="/recipe/history"><li>最近見たレシピ</li></router-link>
        </ul>
    </div>
    <search-modal v-if="liveModal" :signal="signal" @research="reSearch" @close="liveModal = false"></search-modal>
</div>
</template>
<script>
import Editor from "./search-modal.vue";
import { isEmpty } from '../../../common/com-func';

export default {
    components: {
        "search-modal" : Editor
    },
    data() {
        return {
            form: this.createForm(),
            liveModal : false,
            signal: {},
        }
    },
    methods: {
        createForm:function() {
            return {
                name: "",
            };
        },
        search:function() {
            const prm = {};
            Object.keys(this.form).forEach(k => {
                if(this.form[k] !== "") {
                    prm[k] = this.form[k];
                }
            });
            this.searching(prm);
        },
        searching:function(prm) {
            this.$router.push("/recipe?" + new URLSearchParams(prm).toString(), ()=>{});
        },
        openDetail:function() {
            this.liveModal = true;
            this.$nextTick().then(() => {
                this.signal = Object.assign({},this.form);
            });
        },
        reSearch:function(prm) {
            this.form.name = prm.name;
            this.searching(prm);
        },
        sort:function(data) {
            if(isEmpty(this.$route.query.order)) {
                this.searching(location.search+"&order="+data)
            } else {
                let prm = location.search.slice( 0, -10 );
                this.$router.push("/recipe?" + prm+"&order="+data, ()=>{});
            }
        },
    },
    mounted:function() {
        this.form.name = this.$route.query.name ?? "";
    },
}
</script>