var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "menu-block",
      on: {
        click: function ($event) {
          return _vm.click()
        },
      },
    },
    [
      _c("adsense", { staticClass: "menu-ad", attrs: { type: "3" } }),
      _vm._v(" "),
      _c("div", { staticClass: "user-menu" }, [
        _vm.logined
          ? _c(
              "div",
              {
                staticClass: "user-image",
                class: { premium: _vm.userPremium },
              },
              [
                _vm.userBanner && _vm.userPremium
                  ? _c("div", { staticClass: "banner-bg" }, [
                      _c("img", {
                        staticClass: "banner",
                        attrs: {
                          src: `https://healtheat.s3.ap-northeast-1.amazonaws.com${_vm.userBanner}`,
                          alt: "",
                        },
                      }),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.userIcon
                  ? _c("img", {
                      staticClass: "icon",
                      attrs: {
                        src: `https://healtheat.s3.ap-northeast-1.amazonaws.com${_vm.userIcon}`,
                        alt: "",
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                !_vm.userIcon
                  ? _c("img", {
                      staticClass: "icon",
                      attrs: { src: _vm.NoImgUrl, alt: "" },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "h5",
                  [
                    _c(
                      "router-link",
                      { attrs: { to: `/member/${_vm.profileId}` } },
                      [_vm._v(_vm._s(_vm.userName))]
                    ),
                  ],
                  1
                ),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        !_vm.logined ? _c("h5", [_vm._v(_vm._s(_vm.userName))]) : _vm._e(),
        _vm._v(" "),
        !_vm.logined
          ? _c("div", [
              _c("p", [_vm._v("会員登録してる方")]),
              _vm._v(" "),
              _c("button", { on: { click: _vm.login } }, [_vm._v("ログイン")]),
            ])
          : _vm._e(),
        _vm._v(" "),
        !_vm.logined
          ? _c("div", [
              _c("p", [_vm._v("まだ会員登録していない方")]),
              _vm._v(" "),
              _c("button", { on: { click: _vm.register } }, [
                _vm._v("新規会員登録（無料）"),
              ]),
            ])
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "site-menu" },
        [
          _c("h6", [_vm._v("サイトメニュー")]),
          _vm._v(" "),
          _c("router-link", { staticClass: "recipe", attrs: { to: "/" } }, [
            _vm._v("レシピ"),
          ]),
          _vm._v(" "),
          _c(
            "router-link",
            { staticClass: "ranking", attrs: { to: "/recipe/ranking" } },
            [_vm._v("人気のレシピ")]
          ),
          _vm._v(" "),
          _c(
            "router-link",
            { staticClass: "recipe-summary", attrs: { to: "/column" } },
            [_vm._v("コラム")]
          ),
          _vm._v(" "),
          _c(
            "router-link",
            { staticClass: "calculator", attrs: { to: "/calculator" } },
            [_vm._v("栄養価計算")]
          ),
          _vm._v(" "),
          _c(
            "router-link",
            { staticClass: "myfood", attrs: { to: "/myfood" } },
            [_vm._v("みんなのMY食品")]
          ),
          _vm._v(" "),
          _vm.logined
            ? _c(
                "router-link",
                {
                  staticClass: "profile",
                  attrs: { to: `/member/${_vm.profileId}` },
                },
                [_vm._v("プロフィール")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.logined ? _c("h6", [_vm._v("ユーザーメニュー")]) : _vm._e(),
          _vm._v(" "),
          _vm.logined
            ? _c(
                "router-link",
                { staticClass: "mypage", attrs: { to: "/member/info" } },
                [_vm._v("マイページ")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.logined
            ? _c(
                "router-link",
                { staticClass: "myrecipe", attrs: { to: "/member/recipe" } },
                [_vm._v("レシピ管理")]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "router-link",
            { staticClass: "myfood", attrs: { to: "/myfood/custom" } },
            [_vm._v("MY食品管理")]
          ),
          _vm._v(" "),
          _c("h6", [_vm._v("その他")]),
          _vm._v(" "),
          _c("router-link", { staticClass: "terms", attrs: { to: "/terms" } }, [
            _vm._v("利用規約"),
          ]),
          _vm._v(" "),
          _c(
            "router-link",
            { staticClass: "privacy", attrs: { to: "/privacy-policy" } },
            [_vm._v("プライバシーポリシー")]
          ),
          _vm._v(" "),
          _c(
            "router-link",
            { staticClass: "contact", attrs: { to: "/contact" } },
            [_vm._v("お問い合わせ")]
          ),
          _vm._v(" "),
          _c("h6", [_vm._v("公式SNS")]),
          _vm._v(" "),
          _c("sns-link", { staticClass: "sns-icons" }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.logined
        ? _c("button", { staticClass: "logout", on: { click: _vm.logout } }, [
            _vm._v("ログアウト"),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("adsense", { attrs: { type: "3" } }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "site-menu" },
        [
          _c("h6", [_vm._v("新着コラム")]),
          _vm._v(" "),
          _vm._l(_vm.columnList, function (column, i) {
            return _c("div", { key: i, staticClass: "column" }, [
              _c(
                "div",
                {
                  staticClass: "image-box",
                  on: {
                    click: function ($event) {
                      return _vm.columnClick(column)
                    },
                  },
                },
                [
                  column.image
                    ? _c("img", {
                        attrs: {
                          src: `https://healtheat.s3.ap-northeast-1.amazonaws.com${column.image}`,
                          alt: "",
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  !column.image
                    ? _c("img", { attrs: { src: _vm.NoImgUrl, alt: "" } })
                    : _vm._e(),
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "text-box" }, [
                _c(
                  "p",
                  {
                    staticClass: "title",
                    on: {
                      click: function ($event) {
                        return _vm.columnClick(column)
                      },
                    },
                  },
                  [_vm._v(_vm._s(column.title))]
                ),
              ]),
            ])
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "more-recipe" },
            [
              _c("router-link", { attrs: { to: "/column" } }, [
                _vm._v("コラムをもっと見る"),
              ]),
            ],
            1
          ),
        ],
        2
      ),
      _vm._v(" "),
      _c("adsense", { attrs: { type: "3" } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }