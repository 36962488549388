<style lang="scss" scoped>
@import "../../sass/variables";
header {
    position: fixed;
    top: 0;
    z-index: 10;
    width: 100vw;
    height: 60px;
    background-color: #fff;
    box-shadow: $border-color 0px 1px 4px;
    .title_logo {
        height: 100%;
        display: flex;
        align-items: center;
        padding: 0 0 0 0.5rem;
        a{
            display: contents;
            height: 100%;
            img {
                height: 70%;
                &:nth-of-type(2n) {
                    transform: translateX(-10px);
                }
            }
        }
        .login-icon {
            position: absolute;
            right: 65px;
            transform: translateY(-5px);
            .login {
                text-decoration: none;
                color: #818181;
                &::before {
                    width: 40px;
                    text-align: center;
					font-family: "Font Awesome 5 Free";
					font-weight: bold;
					display: block;
					font-size: 24px;
                    content: '\f2f6';
				}
                &.mypage {
                    &::before {
                        width: 50px;
                        content: '\f2bb';
                    }
                }
                span {
                    color: $black-font;
                    position: absolute;
                    transform: translateY(-7px);
                    font-size: 10px;
                }
            }
        }
    }
}
.burger-menu {
    .menu-block {
        width: 100%;
        padding: 5rem 0 0 0;
        .user-menu {
            margin: 0 0 1rem;
            .user-image {
                padding: 0 0 0 1rem;
                a {
                    text-decoration: none;
                    display: flex;
                    align-items: end;
                    .banner-bg {
                        width: 100%;
                        aspect-ratio: 3/1;
                        position: absolute;
                        top: 0;
                        left: 0;
                        z-index: -1;
                        &::after {
                            content: '';
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            aspect-ratio: 3/1;
                            background-image: linear-gradient(180deg, transparent 0 60%, #fff 100%);
                        }
                        .banner {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }
                    .icon {
                        width: 45px;
                        height: 45px;
                        object-fit: cover;
                        border-radius: 50%;
                    }
                    h5 {
                        padding: 0;
                    }
                }
            }
            h5 {
                color: $brown-font;
                padding: 0 0 0 2rem;
                a {
                    text-decoration: none;
                    color: $brown-font;
                }
            }
            p {
                margin: 1rem 0 0.2rem 1rem;
            }
        }
        .site-menu {
            h6 {
                padding: 0.5rem 1rem;
                color: $brown-font;
                background-color: #fff4dd;
            }
            >a {
                display: flex;
                align-items: center;
                text-decoration: none;
                color: $black-font;
                margin: 0 1rem;
                padding: 0 0 0.6rem;
                font-size: 16px;
                &::before {
					font-family: "Font Awesome 5 Free";
					font-weight: bold;
					display: inline-block;
					font-size: 20px;
                    width: 25px;
                    text-align: center;
                    margin: 0 0.2rem 0 0;
				}
                &::after {
                    content: "＞";
                    position: absolute;
                    right: 1rem;
                    font-size: 12px;
                }
				&.calculator::before {
					content: '\f1ec';
				}
				&.recipe::before {
					content: '\f518';
				}
				&.ranking::before {
					content: '\f521';
				}
				&.recipe-summary::before {
					content: '\f02d';
				}
                &.myfood::before {
                	content: '\f787';
				}
                &.myrecipe::before {
					content: '\f573';
				}
				&.mypage::before {
					content: '\f2bb';
				}
				&.terms::before {
					content: '\f022';
				}
				&.privacy::before {
					content: '\f502';
				}
				&.contact::before {
					content: '\f0e0';
				}
            }
            .sns-icons {
                display: flex;
                justify-content: initial;
                margin: 0.6rem 0 0 1rem;
            }
        }
        button {
            display: block;
            width: 80%;
            background-color: $main-color-red;
            color: #fff;
            border-radius: 20px;
            margin: 0 auto 1rem;
            padding: 0.3rem;
            border: none;
            transition: 0.3s;
            &.logout {
                background-color: #888;
                margin: 4rem auto;
            }
            &:hover {
                opacity: 0.6;
            }
        }
    }
    .menu-btn{
        position: fixed;
        top: 25px;
        right: 35px;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 101;
        span, span:before, span:after {
            content: '';
            display: block;
            height: 3px;
            width: 25px;
            border-radius: 3px;
            background-color: #818181;
            position: absolute;
        }
        span {
            &:before {
                bottom: 8px;
            }
            &:after {
                top: 8px;
            }
        }
        >div {
            color: $black-font;
            position: absolute;
            transform: translateY(20px);
            width: 40px;
            font-size: 10px;
            transition: all 0.3s;
        }
        &.is-active {
            span {
                background-color: rgba(255, 255, 255, 0);
            }
            span {
                &::before {
                    background-color: #565656;
                    bottom: 0;
                    transform: rotate(45deg);
                    transition: all 0.3s;
                }
                &::after {
                    background-color: #565656;
                    top: 0;
                    transform: rotate(-45deg);
                    transition: all 0.3s;
                }
            }
            >div {
                display: none;
            }
        }
    }
    .background {
        display: none;
        position: fixed;
        z-index: 99;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: black;
        &.is-active {
            display: block;
            opacity: 0.5;
        }
    }
    .menu{
        background-color: #fff;
        position: fixed;
        right: 0;
        z-index: 100;
        width: 75vw;
        height: 100vh;
        transform: translateX(100vw);
        transition: all .3s linear;
        overflow-y: auto;
        &.is-active {
            transform: translateX(0);
        }
    }
}
@media screen and (min-width: 1000px) {
    .burger-menu,
    .login-icon {
        display: none;
    }
    header {
        position: initial;
        .title_logo {
            width: 1100px;
            margin: 0 auto;
            a img {
                height: 90%;
            }
        }
    } 
}
</style>
<template>
<header>
    <!-- バーガーメニュー -->
    <div class="burger-menu">
        <div class="menu-btn" :class="{'is-active' : open }" @click="open=!open">
            <span></span>
            <div>メニュー</div>
        </div>
        <div class="background" :class="{'is-active' : open }" @click="bkClick"></div>
        <div class="menu" :class="{'is-active' : open }">
            <div class="menu-block" @click="click()">
                <div class="user-menu">
                    <div v-if="logined" class="user-image">
                        <router-link :to="`/member/${profileId}`">
                            <div v-if="userBanner" class="banner-bg">
                                <img class="banner" :src="`https://healtheat.s3.ap-northeast-1.amazonaws.com${userBanner}`" alt="">
                            </div>
                            <img v-if="userIcon" class="icon" :src="`https://healtheat.s3.ap-northeast-1.amazonaws.com${userIcon}`" alt="">
                            <img v-if="!userIcon" class="icon" :src="NoImgUrl" alt="">
                            <h5>{{userName}}</h5>
                        </router-link>
                    </div>
                    <h5 v-if="!logined">{{userName}}</h5>
                    <div v-if="!logined">
                        <p>会員登録してる方</p>
                        <button @click="login">ログイン</button>
                    </div>
                    <div v-if="!logined">
                        <p>まだ会員登録していない方</p>
                        <button @click="register">新規会員登録（無料）</button>
                    </div>
                </div>
                <div class="site-menu">
                    <h6>サイトメニュー</h6>
                    <router-link to="/" class="recipe">レシピ</router-link>
                    <router-link to="/recipe/ranking" class="ranking">人気のレシピ</router-link>
                    <router-link to="/column" class="recipe-summary">コラム</router-link>
                    <router-link to="/calculator" class="calculator">栄養価計算</router-link>
                    <router-link to="/myfood" class="myfood">みんなのMY食品</router-link>

                    <h6 v-if="logined">ユーザーメニュー</h6>
                    <router-link v-if="logined" to="/member/info" class="mypage">マイページ</router-link>
                    <router-link v-if="logined" to="/member/recipe" class="myrecipe">レシピ管理</router-link>
                    <router-link to="/myfood/custom" class="myfood">MY食品管理</router-link>

                    <h6>その他</h6>
                    <router-link to="/terms" class="terms">利用規約</router-link>
                    <router-link to="/privacy-policy" class="privacy">プライバシーポリシー</router-link>
                    <router-link to="/contact" class="contact">お問い合わせ</router-link>

                    <h6>公式SNS</h6>
                    <sns-link class="sns-icons"></sns-link>
                </div>

                <button v-if="logined" @click="logout" class="logout">ログアウト</button>
            </div>
        </div>
    </div>
    <div class="title_logo">
        <router-link to="/" class="menu-item"><img src="/img/icon.png" alt=""></router-link>
        <router-link to="/" class="menu-item"><img src="/img/title_logo.png" alt=""></router-link>
        <div class="login-icon">
            <router-link v-if="!logined" to="/login" class="login"><span>ログイン</span></router-link>
        </div>
        <div class="login-icon">
            <router-link v-if="logined" to="/member/info" class="login mypage"><span>マイページ</span></router-link>
        </div>
    </div>
</header>
</template>
<script>
import {mapGetters} from "vuex";
import { USER_ARIES } from "../store/modules/user-store";
import snsLink from "../components/sns-link.vue";
import {NO_IMG_URL} from "../common/constants";

export default {
    components: {
        "sns-link" : snsLink,
    },
    data:function() {
        return {
            open: false,
            NoImgUrl: NO_IMG_URL,
        };
    },
    computed: {
        ...mapGetters(USER_ARIES,["logined","userName","profileId","userIcon","userBanner"]),
    },
    methods: {
        bkClick:function() {
            this.open = false;
        },
        click:function() {
            this.open = false;
        },
        login:function() {
            this.$router.push({path:"/login"}).catch(err => {});
        },
        register:function() {
            this.$router.push({path:"/register"}).catch(err => {});
        },
        logout:function() {
            location.href = "/member/logout";
        },
    },
    mounted:function() {
    }
}
</script>