<style lang="scss" scoped>
@import "../../../../sass/variables";
.title_wrap {
    span {
        &::before {
            content: '\f518';
            font-family: "Font Awesome 5 Free";
            font-weight: bold;
            font-size: 1.6rem;
            margin: 0 0.3rem 0 0;
        }
    }
}
.recipe-main {
    .recipe-name {
        font-size: 1.2rem;
        padding: 0 0.5rem;
    }
    .image-box {
        position: relative;
        .category-name {
            position: absolute;
            top: 0.3rem;
            left: 0.3rem;
            text-decoration: none;
            background-color: rgba(255, 255, 255, 0.9);
            padding: 0.1rem 1rem;
            border-radius: 4px;
            line-height: 1.2rem;
            color: initial;
            font-size: 0.8rem;
        }
        .favorite-btn {
            position: absolute;
            right: 0.5rem;
            bottom: 0.4rem;
            background-color: rgba(255, 255, 255, 0.9);
            padding: 0.3rem 0.5rem;
            border-radius: 4px;
            line-height: 1.2rem;
            font-size: 0.8rem;
            margin: 0;
            transition: 0.3s;
            &:hover {
                cursor: pointer;
                opacity: 0.8;
            }
            &:before {
                content: '\f005';
                font-family: "Font Awesome 5 Free";
                font-weight: normal;
                display: inline-block;
                margin: 0 0.1rem 0 0;
            }
            &.is-active {
                background-color: #f4b500;
                color: #fff;
                &:before {
                    font-weight: bold;
                }
            }
        }
        .recipe-image {
            width: 100%;
            object-fit: cover;
            aspect-ratio: 1 / 1;
        }
    }
    .member {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0.5rem 1rem;
        .name {
            display: flex;
            align-items: center;
            a {
                text-decoration: none;
                color: $black-font;
                img {
                    width: 35px;
                    height: 35px;
                    object-fit: cover;
                    border-radius: 50%;
                }
                p {
                    margin: 0 0 0 0.5rem;
                }
            }
        }
        .follow {
            background-color: #fff;
            color: $main-color-red;
            border: 2px solid $main-color-red;
            border-radius: 6px;
            padding: 0.2rem 1rem;
            transition: 0.3s;
            &:hover {
                cursor: pointer;
                box-shadow: 0 0 0 3px #f2e8d6;
            }
            &.is-active {
                background-color: $main-color-red;
                color: #fff;
            }
        }
        .recipe-edit {
            min-width: 120px;
            background-color: $main-color-green;
            color: #fff;
            border: none;
            border-radius: 6px;
            padding: 0.2rem 1rem;
            transition: 0.3s;
            &:hover {
                cursor: pointer;
                color: #fff;
                box-shadow: 0 0 5px $border-color;
            }
        }
    }
    .info {
        display: flex;
        margin: 0 0.2rem;
        div {
            position: relative;
            width: 50%;
            text-align: center;
            margin: 0 0.1rem;
            padding: 0.2rem 0 0.4rem;
            border-radius: 4px;
            line-height: 1.2rem;
            p {
                margin: 0;
                &::before {
                    font-family: "Font Awesome 5 Free";
                    font-weight: bold;
                    font-size: 1.2rem;
                    display: inline-block;
                    margin: 0 0.3rem 0 0;
                    transform: translateY(2px);
                }
            }
            span {
                font-size: 0.8rem;
            }
            .one-serving {
                position: absolute;
                right: 0.2rem;
                bottom: 0.2rem;
                font-size: 0.6rem;
            }
        }
        .cooking-time {
            background-color: #a7d9ab;
            color: #344435;
            p {
                &::before {
                    content: '\f017';
                }
            }
        }
        .price {
            background-color: #e7baa3;
            color: #6d411c;
            p {
                &::before {
                    content: '\f157';
                }
            }
        }
        .energy {
            background-color: #f7d27f;
            color: #6d411c;
            transition: 0.3s;
            &:hover {
                cursor: pointer;
                opacity: 0.8;
            }
            p {
                &::before {
                    content: '\f06d';
                }
            }
        }
    }
    .more-energy {
        display: flex;
        justify-content: end;
        font-size: 0.8rem;
        color: #617dd3;
        margin: 0 0.4rem 0 0;
        text-decoration: underline;
        &:hover {
            cursor: pointer;
        }
    }
    .recipe-note {
        margin: 0.5rem 1rem;
        white-space: pre-wrap;
    }
}
.recipe-title {
    font-size: 1.4rem;
    font-weight: bold;
    span {
        font-weight: normal;
        font-size: 1rem;
    }
}
.food-note {
    display: block;
    margin: 0 0.5rem;
    font-size: 0.8rem;
}
.recipe-info {
    margin: 1rem 0 0;
    ul {
        list-style: none;
        padding: 0 1rem 0.5rem;
        li {
            padding: 1rem 0.5rem 0;
            border-bottom: 1px solid $border-color;
            &.is-group {
                border-bottom: none;
            }
            .list {
                display: flex;
                justify-content: space-between;
                align-items: baseline;
                transition: 0.3s;
                &:hover {
                    cursor: pointer;
                    opacity: 0.8;
                }
                h3 {
                    max-width: 65%;
                    font-size: 1rem;
                    margin: 0 1rem 0.5rem 0;
                }
                .sub-weight {
                    font-size: 0.8rem;
                    margin: 0 0 0 0.2rem;
                }
            }
            .group-food-wrap {
                p {
                    font-weight: bold;
                    margin: 0;
                    &::before {
                        content: "";
                        display: inline-block;
                        height: 12px;
                        width: 12px;
                        margin: 0 0.2rem 0 0;
                        background-color: #000;
                    }
                }
                .group-food {
                    padding: 1rem 0 0 0.5rem;
                    border-bottom: 1px solid $border-color;
                }
            }
        }
    }
}
.recipe-make {
    ul {
        list-style: none;
        padding: 0 0.5rem 0.5rem;
        li {
            margin: 1rem 0 0;
            padding: 0 0.5rem 0.5rem;
            border-bottom: 1px solid $border-color;
            div {
                display: flex;
                span {
                    display: flex;
                    justify-content: center;
                    color: #fff;
                    min-width: 25px;
                    height: 25px;
                    background-color: #ffae4e;
                    border-radius: 4px;
                    font-weight: bold;
                }
                h3 {
                    font-size: 0.9rem;
                    white-space: pre-wrap;
                    margin: 0.2rem 0.4rem 0;
                }
            }
            img {
                width: 100%;
                height: auto;
                object-fit: cover;
                aspect-ratio: 1 / 1;
                margin: 0.5rem 0 0;
            }
        }
    }
}
.my-food-link {
    margin: 2rem 0 0;
}
.make-point {
    padding: 0 0 2rem;
    white-space: pre-wrap;
    p {
        margin: 0.5rem 1rem;
    }
}
a {
    text-decoration: none;
    color: $black-font;
    transition: 0.3s;
    .member-info {
        position: relative;
        margin: 1rem 0.5rem 2rem;
        padding: 0.5rem;
        background-color: #fff2dc;
        border-radius: 4px;
        .member-title {
            font-size: 1.1rem;
            margin: 0 0 0.5rem;
        }
        .member-content-wrap {
            display: flex;
            img {
                width: 50px;
                height: 50px;
                object-fit: cover;
                border-radius: 50%;
            }
            .member-content {
                display: flex;
                align-items: center;
                .member-text {
                    margin: 0 1.5rem 0 0.5rem;
                    h4 {
                        font-size: 1rem;
                        font-weight: bold;
                    }
                    p {
                        font-size: 0.8rem;
                        line-height: 1rem;
                        white-space: pre-wrap;
                    }
                }
                .arrow-right {
                    position: absolute;
                    top: 50%;
                    right: 1rem;
                    display: block;
                    min-width: 12px;
                    height: 12px;
                    border: 0px;
                    border-top: solid 1px #000;
                    border-right: solid 1px #000;
                    transform: rotate(45deg);
                }
            }
        }
    }
    &:hover {
        cursor: pointer;
        opacity: 0.8;
    }
}
@media screen and (min-width: 1000px) {
    .recipe-detail {
        padding: 1rem 2rem;
        .recipe-main {
            .pc-flex {
                display: flex;
                .image-box {
                    width: 400px;
                    .recipe-image {
                        width: 100%;
                        margin: 0 auto;
                    }
                    .favorite-btn {
                        top: 330px;
                        bottom: auto;
                    }
                }
                .pc-right {
                    width: 400px;
                    .member {
                        padding: 0;
                        width: 90%;
                        margin: 0 auto;
                    }
                    .info {
                        display: block;
                        div {
                            width: 90%;
                            margin: 0.5rem auto 0;
                        }
                    }
                    .more-energy {
                        width: 90%;
                        margin: 0 auto;
                    }
                    .recipe-note {
                        width: 90%;
                        margin: 1rem auto 0;
                    }
                }
            }
        }
        .recipe-make {
            ul {
                li {
                    display: flex;
                    justify-content: space-between;
                    img {
                        width: 200px;
                        height: 200px;
                        margin: 0;
                    }
                }
            }
        }
    }
}

</style>
<template>
<div>
    <div class="user_contents" v-if="showDetail">
        <div class="title_wrap">
            <span>レシピ</span>
        </div>
        <search-component></search-component>
        <div class="recipe-detail">
            <div class="recipe-main">
                <h1 class="recipe-name">{{recipe.name}}</h1>
                <div class="pc-flex">
                    <div class="image-box">
                        <router-link class="category-name" :to="`/recipe/category/${recipe.category.url_name}`">{{recipe.category.name}}</router-link>
                        <p @click="favoriteClick" class="favorite-btn" :class="{'is-active' : isFavorite }">お気に入り登録<span v-if="isFavorite">済み</span></p>
                        <img v-if="recipe.thumbnail" class="recipe-image" :src="`https://healtheat.s3.ap-northeast-1.amazonaws.com${recipe.thumbnail}`" :alt="`${recipe.name}の画像`">
                        <img v-if="!recipe.thumbnail" class="recipe-image" :src="NoImgUrl" :alt="`${recipe.name}の画像`">
                    </div>
                    <div class="pc-right">
                        <div class="member">
                            <div class="name">
                                <router-link :to="`/member/${recipe.member.profile_id}`">
                                    <img v-if="recipe.member.image_icon" class="icon" :src="`https://healtheat.s3.ap-northeast-1.amazonaws.com${recipe.member.image_icon}`" alt="">
                                    <img v-if="!recipe.member.image_icon" class="icon" :src="NoImgUrl" alt="">
                                </router-link>
                                <router-link :to="`/member/${recipe.member.profile_id}`"><p>{{recipe.member.name}}</p></router-link>
                            </div>
                            <div v-if="this.user_uuid !== recipe.member.uuid" @click="followClick" class="follow" :class="{'is-active' : isFollowed }">フォロー<span v-if="isFollowed">中</span></div>
                            <div v-if="this.user_uuid == recipe.member.uuid" @click="recipeEdit(recipe)" class="recipe-edit" >レシピを編集</div>
                        </div>
                        <div class="info">
                            <div class="cooking-time">
                                <p>調理時間</p>
                                <span>{{recipe.cooking_time}}分</span>
                            </div>
                            <div class="price" v-if="recipe.price">
                                <p>費用目安</p>
                                <span>{{recipe.price}}円</span>
                            </div>
                            <div class="energy" @click="openRecipeDetail">
                                <p>エネルギー</p>
                                <span>{{recipe.energy}}kcal</span>
                                <span class="one-serving">※1食分</span>
                            </div>
                        </div>
                        <span class="more-energy" @click="openRecipeDetail">栄養素を見る>></span>
                        <div class="recipe-note">
                            <p>{{recipe.note}}</p>
                        </div>
                    </div>
                </div>
            </div>
            <adsense type="1" />
            <div class="sns-share">
                <social-sharing
                    :url="`https://healtheat.jp/recipe/${recipe.id}\n\n`"
                    :title="`${recipe.name}\n`"
                    :hashtags="`${recipe.name},${recipe.category.name},ヘルシート`"
                    twitter-user="healtheat_jp"
                    inline-template
                >
                    <network class="button twitter" network="twitter">ポスト</network>
                </social-sharing>
                <social-sharing
                    :url="`https://healtheat.jp/recipe/${recipe.id}`"
                    :title="recipe.name"
                    :description="recipe.name"
                    inline-template
                >
                    <network class="button line" network="line">LINE</network>
                </social-sharing>
                <social-sharing
                    :url="`https://healtheat.jp/recipe/${recipe.id}`"
                    :title="recipe.name"
                    :description="recipe.note"
                    :hashtags="`${recipe.name},${recipe.category.name},ヘルシート`"
                    inline-template
                >
                    <network class="button facebook" network="facebook">シェア</network>
                </social-sharing>
                <social-sharing
                    :url="`https://healtheat.jp/recipe/${recipe.id}`"
                    :title="recipe.name"
                    :description="recipe.note"
                    :media="`https://healtheat.s3.ap-northeast-1.amazonaws.com${recipe.thumbnail}`"
                    inline-template
                >
                    <network class="button pinterest" network="pinterest">保存</network>
                </social-sharing>
            </div>
            <div class="recipe-info">
                <h2 class="recipe-title">材料<span>（{{recipe.serving}}人分）</span></h2>
                <span class="food-note disp-sp">※食材をタップすると栄養素を確認できます。</span>
                <span class="food-note disp-pc">※食材をクリックすると栄養素を確認できます。</span>
                <ul>
                    <li v-for="(food,i) in recipe.recipe_food" :key="i" :class="{'is-group' : food.food_group.length !== 0 }">
                        <div @click="openFoodDetail(food.recipe_food_master, food.weight, false)" class="list" v-if="food.recipe_food_master">
                            <h3>{{food.recipe_food_master.recipe_name}}</h3>
                            <div>
                                <div v-if="food.weight_type && food.weight_type.name != 'g'">
                                    <span v-if="food.weight_type.name == '大さじ' || food.weight_type.name == '小さじ'">{{food.weight_type.name}}</span>
                                    <span>{{food.one_weight}}</span>
                                    <span v-if="food.weight_type.name != '大さじ' && food.weight_type.name != '小さじ'">{{food.weight_type.name}}</span>
                                    <span class="sub-weight">({{food.weight}}g)</span>
                                </div>
                                <span v-if="!food.weight_type || food.weight_type.name == 'g'">{{food.weight}}g</span>
                            </div>
                        </div>
                        <div @click="openFoodDetail(food.recipe_my_food, food.weight, true)" class="list" v-if="food.recipe_my_food">
                            <h3>{{food.recipe_my_food.name}}</h3>
                            <span>{{food.weight}}g</span>
                        </div>

                        <div v-if="food.food_group.length" class="group-food-wrap">
                            <p>{{food.group_name}}</p>
                            <div class="group-food" v-for="(gfood,i2) in food.food_group" :key="i2">
                                <div @click="openFoodDetail(gfood.recipe_food_master, gfood.weight, false)" class="list" v-if="gfood.recipe_food_master">
                                    <h3>{{gfood.recipe_food_master.recipe_name}}</h3>
                                    <div>
                                        <div v-if="gfood.weight_type && gfood.weight_type.name != 'g'">
                                            <span v-if="gfood.weight_type.name == '大さじ' || gfood.weight_type.name == '小さじ'">{{gfood.weight_type.name}}</span>
                                            <span>{{gfood.one_weight}}</span>
                                            <span v-if="gfood.weight_type.name != '大さじ' && gfood.weight_type.name != '小さじ'">{{gfood.weight_type.name}}</span>
                                            <span class="sub-weight">({{gfood.weight}}g)</span>
                                        </div>
                                        <span v-if="!gfood.weight_type || gfood.weight_type.name == 'g'">{{gfood.weight}}g</span>
                                    </div>
                                </div>
                                <div @click="openFoodDetail(gfood.recipe_my_food, gfood.weight, true)" class="list" v-if="gfood.recipe_my_food">
                                    <h3>{{gfood.recipe_my_food.name}}</h3>
                                    <span>{{gfood.weight}}g</span>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <adsense type="1" />
            <div class="recipe-make">
                <h2 class="recipe-title">作り方</h2>
                <ul>
                    <li v-for="(process,i) in recipe.recipe_process" :key="i" v-show="process.note || process.image">
                        <div>
                            <span>{{i+1}}</span>
                            <h3>{{process.note}}</h3>
                        </div>
                        <img v-if="process.image" :src="`https://healtheat.s3.ap-northeast-1.amazonaws.com${process.image}`" :alt="`${recipe.name}の作り方${i+1}の画像`">
                    </li>
                </ul>
            </div>
            <div v-if="recipe.myFoodLink" class="my-food-link">
                <h2 class="recipe-title">このレシピで使った商品</h2>
                <div class="url_link" v-html="myFood" v-for="(myFood,i) in recipe.myFoodLink" :key="i"></div>
            </div>
            <adsense type="1" />
            <div v-if="recipe.make_point" class="make-point">
                <h2 class="recipe-title">ワンポイント</h2>
                <p>{{recipe.make_point}}</p>
            </div>
            <router-link :to="`/member/${recipe.member.profile_id}`">
                <div class="member-info">
                    <p class="member-title">投稿者</p>
                    <div class="member-content-wrap">
                        <img v-if="recipe.member.image_icon" class="icon" :src="`https://healtheat.s3.ap-northeast-1.amazonaws.com${recipe.member.image_icon}`" alt="">
                        <img v-if="!recipe.member.image_icon" class="icon" :src="NoImgUrl" alt="">
                        <div class="member-content">
                            <div class="member-text">
                                <h4>{{recipe.member.name}}</h4>
                                <p>{{recipe.member.note}}</p>
                            </div>
                            <span class="arrow-right"></span>
                        </div>
                    </div>
                </div>
            </router-link>
            <div class="recipe-new" v-if="relationList.length != 0">
                <h2 class="recipe-title">関連レシピ</h2>
                <div class="recipes">
                    <div class="recipe" v-for="(recipe,i) in relationList" :key="i">
                        <div v-if="!recipe.ad">
                            <router-link :to="`/recipe/${recipe.id}`">
                                <img v-if="recipe.thumbnail" :src="`https://healtheat.s3.ap-northeast-1.amazonaws.com${recipe.thumbnail}`" :alt="`${recipe.name}の画像`">
                                <img v-if="!recipe.thumbnail" :src="NoImgUrl" :alt="`${recipe.name}の画像`">
                            </router-link>
                            <router-link :to="`/recipe/${recipe.id}`">
                                <h3>{{recipe.name}}</h3>
                            </router-link>
                            <div class="member-name">
                                <router-link :to="`/member/${recipe.member.profile_id}`">by{{recipe.member.name}}</router-link>
                            </div>
                        </div>
                        <ad-banner class="recipe-ad" v-if="recipe.ad" type="0" text="true" />
                    </div>
                </div>
            </div>
            <adsense type="1" />
            <div class="column-new">
                <h2 class="recipe-title">新着コラム</h2>
                <div class="column row" v-for="(column,i) in columnList" :key="i">
                    <div class="flex">
                        <div @click="columnClick(column)">
                            <img v-if="column.image" :src="`https://healtheat.s3.ap-northeast-1.amazonaws.com${column.image}`" :alt="`${column.title}の画像`">
                            <img v-if="!column.image" :src="NoImgUrl" :alt="`${column.title}の画像`">
                        </div>
                        <div>
                            <h3 @click="columnClick(column)" class="title">{{column.title}}</h3>
                            <p @click="columnClick(column)">{{column.body}}</p>
                        </div>
                    </div>
                </div>
                <div class="more-recipe">
                    <router-link to="/column">コラムをもっと見る</router-link>
                </div>
            </div>
            <adsense type="1" />
        </div>
        <login-info-modal v-if="liveModal" :signal="signal" @close="liveModal = false"></login-info-modal>
        <premium-info-modal v-if="liveModal2" :signal="signal" @close="liveModal2 = false"></premium-info-modal>
        <detail-modal v-if="liveModal3" :signal="signal" @close="liveModal3 = false"></detail-modal>
        <editor-modal v-if="liveModal4" :signal="signal" @research="research" @close="liveModal4 = false"></editor-modal>
        <msg-toast :signal="toastMsg"></msg-toast>
    </div>
</div>
</template>
<script>
import searchComponent from "./search-component.vue";
import {NO_IMG_URL} from "../../../common/constants";
import loginInfoModal from "../../../components/login-info-modal.vue";
import premiumInfoModal from "../../../components/premium-info-modal.vue";
import detailModal from "../calculator/detail-modal.vue";
import Api from "../../../common/fetch-wrapper";
import {mapGetters} from "vuex";
import { USER_ARIES } from "../../../store/modules/user-store";
import Editor from "../member/recipe/editor-modal.vue";
import MsgToast from "../../../components/msg-toast";
import AdBanner from "../../../components/ad-banner.vue";
import Adsense from "../../../components/adsense.vue";


export default {
    props: {
        recipeId: "",
    },
    components: {
        "search-component" : searchComponent,
        "login-info-modal" : loginInfoModal,
        "premium-info-modal" : premiumInfoModal,
        "detail-modal" : detailModal,
        "editor-modal" : Editor,
        "msg-toast" : MsgToast,
        "ad-banner" : AdBanner,
        "adsense" : Adsense,
    },
    data() {
        return {
            showDetail: false,
            isFavorite: false,
            isFollowed: false,
            user_uuid: "",
            recipe: {},
            relationList: [],
            columnList: [],
            NoImgUrl: NO_IMG_URL,
            liveModal : false,
            liveModal2 : false,
            liveModal3 : false,
            liveModal4 : false,
            signal: {},
            toastMsg: {},
        }
    },
    watch: {
        $route(to, from) {
            this.showDetail = false;
            this.getRecipe();
        }
    },
    computed: {
        ...mapGetters(USER_ARIES, ["logined","userUUID","userPremium"])
    },
    methods: {
        getRecipe:function() {
            Api.get("/recipe/detail/" + this.recipeId).then(x => {
                // 会員もしくはレシピが公開されてない場合はリダイレクト
                if(x.member.status !== 1 || x.status !== 2) {
                    return location.href = "/not-found";
                }
                this.recipe = x;

                document.title = `${this.recipe.name} レシピ・作り方 | ヘルシート`;
                document.querySelector("meta[name='description']").setAttribute('content', this.recipe.note);
                document.querySelector("meta[name='thumbnail']").setAttribute('content', `https://healtheat.s3.ap-northeast-1.amazonaws.com${this.recipe.thumbnail}`);
                document.querySelector("meta[property='og:site_name']").setAttribute('content', this.recipe.name);
                document.querySelector("meta[property='og:title']").setAttribute('content', this.recipe.name);
                document.querySelector("meta[property='og:description']").setAttribute('content', this.recipe.note);
                document.querySelector("meta[property='og:image']").setAttribute('content', `https://healtheat.s3.ap-northeast-1.amazonaws.com${this.recipe.thumbnail}`);

                if(this.logined && this.recipe.favorite) {
                    if(this.recipe.favorite.member.uuid == this.userUUID) {
                        this.isFavorite = true;
                    }
                }
                if(this.logined) {
                    this.user_uuid = this.userUUID;
                    if(this.recipe.member.followed[0]) {
                        this.isFollowed = true;
                    }
                }

                Api.get("/recipe/relation/" + this.recipe.uuid).then(x => {
                    if(!this.userPremium) {
                        x.forEach(function(e, index){
                            e.ad = false;
                            if((index + 1) % 3 == 0) {
                                x.splice(index, -1, {ad: true})
                            }
                        });
                        if(x.length == 6) {
                            x.push({ad: true},{ad: true});
                        }
                    }
                    this.relationList = x;
                });
                Api.get("/recipe/view/" + this.recipeId);
                this.showDetail = true;
            });
        },
        getColumn:function() {
            Api.get("/column/new").then(x => {
                this.columnList = x;
            });
        },
        columnClick:function(data) {
            if(data.type == 1){
                this.$router.push({path:`/recipe-summary/${data.id}`}).catch(err => {});
            } else {
                this.$router.push({path:`/column/${data.id}`}).catch(err => {});
            }
        },
        favoriteClick:function() {
            if(this.logined) {
                Api.get("/member/favorite-count").then(x => {
                    if(x >= 50 && !this.isFavorite && !this.userPremium) {
                        const data = {};
                        data.message = "プレミアム会員になると無制限にお気に入り保存することができます。";
                        this.liveModal2 = true;
                        this.recipe.recipeDetail = true;
                        this.$nextTick().then(() => {
                            this.signal = Object.assign({},data);
                        });
                    } else {
                        const form = new FormData();
                        form.append("recipe_id",this.recipe.id);
                        Api.post("/recipe/favorite/toggle", form, (d) => {
                            if(d.ok) {
                                this.isFavorite = false;
                                this.getRecipe();
                            }
                        });
                    }
                });
            } else {
                this.liveModal = true;
                this.$nextTick().then(() => {
                    this.signal = Object.assign({});
                });
            }
        },
        followClick:function() {
            if(this.logined) {
                const form = new FormData();
                form.append("member_uuid",this.recipe.member.uuid);
                Api.post("/member/followed/toggle", form, (d) => {
                    if(d.ok) {
                        this.isFollowed = false;
                        this.getRecipe();
                    }
                });
            } else {
                this.liveModal = true;
                this.$nextTick().then(() => {
                    this.signal = Object.assign({});
                });
            }
        },
        openRecipeDetail:function() {
            this.liveModal3 = true;
            this.recipe.recipeDetail = true;
            this.$nextTick().then(() => {
                this.signal = Object.assign({},this.recipe);
            });
        },
        openFoodDetail:function(food, weight, myfood) {
            let foodEnergy = {};
            foodEnergy.weight = weight;
            foodEnergy.myfood = myfood;
            foodEnergy.name = food.name;
            foodEnergy.note = food.note;

            if(myfood) {
                foodEnergy.image = food.image;
                foodEnergy.energy = isNaN(food.energy*weight/food.weight) ? 0 : food.energy*weight/food.weight;
                foodEnergy.protein = isNaN(food.protein*weight/food.weight) ? 0 : food.protein*weight/food.weight;
                foodEnergy.lipid = isNaN(food.lipid*weight/food.weight) ? 0 : food.lipid*weight/food.weight;
                foodEnergy.dietary_fiber = isNaN(food.dietary_fiber*weight/food.weight) ? 0 : food.dietary_fiber*weight/food.weight;
                foodEnergy.carbohydrate = isNaN(food.carbohydrate*weight/food.weight) ? 0 : food.carbohydrate*weight/food.weight;
                foodEnergy.potassium = isNaN(food.potassium*weight/food.weight) ? 0 : food.potassium*weight/food.weight;
                foodEnergy.calcium = isNaN(food.calcium*weight/food.weight) ? 0 : food.calcium*weight/food.weight;
                foodEnergy.magnesium = isNaN(food.magnesium*weight/food.weight) ? 0 : food.magnesium*weight/food.weight;
                foodEnergy.iron = isNaN(food.iron*weight/food.weight) ? 0 : food.iron*weight/food.weight;
                foodEnergy.vitamin_a = isNaN(food.vitamin_a*weight/food.weight) ? 0 : food.vitamin_a*weight/food.weight;
                foodEnergy.vitamin_d = isNaN(food.vitamin_d*weight/food.weight) ? 0 : food.vitamin_d*weight/food.weight;
                foodEnergy.vitamin_e = isNaN(food.vitamin_e*weight/food.weight) ? 0 : food.vitamin_e*weight/food.weight;
                foodEnergy.vitamin_b1 = isNaN(food.vitamin_b1*weight/food.weight) ? 0 : food.vitamin_b1*weight/food.weight;
                foodEnergy.vitamin_b2 = isNaN(food.vitamin_b2*weight/food.weight) ? 0 : food.vitamin_b2*weight/food.weight;
                foodEnergy.vitamin_b6 = isNaN(food.vitamin_b6*weight/food.weight) ? 0 : food.vitamin_b6*weight/food.weight;
                foodEnergy.vitamin_b12 = isNaN(food.vitamin_b12*weight/food.weight) ? 0 : food.vitamin_b12*weight/food.weight;
                foodEnergy.vitamin_c = isNaN(food.vitamin_c*weight/food.weight) ? 0 : food.vitamin_c*weight/food.weight;
                foodEnergy.salt = isNaN(food.salt*weight/food.weight) ? 0 : food.salt*weight/food.weight;
            }else{
                foodEnergy.energy = isNaN(food.energy/100*weight) ? 0 : food.energy/100*weight;
                foodEnergy.protein = isNaN(food.protein/100*weight) ? 0 : food.protein/100*weight;
                foodEnergy.lipid = isNaN(food.lipid/100*weight) ? 0 : food.lipid/100*weight;
                foodEnergy.dietary_fiber = isNaN(food.dietary_fiber/100*weight) ? 0 : food.dietary_fiber/100*weight;
                foodEnergy.carbohydrate = isNaN(food.carbohydrate/100*weight) ? 0 : food.carbohydrate/100*weight;
                foodEnergy.potassium = isNaN(food.potassium/100*weight) ? 0 : food.potassium/100*weight;
                foodEnergy.calcium = isNaN(food.calcium/100*weight) ? 0 : food.calcium/100*weight;
                foodEnergy.magnesium = isNaN(food.magnesium/100*weight) ? 0 : food.magnesium/100*weight;
                foodEnergy.iron = isNaN(food.iron/100*weight) ? 0 : food.iron/100*weight;
                foodEnergy.vitamin_a = isNaN(food.vitamin_a/100*weight) ? 0 : food.vitamin_a/100*weight;
                foodEnergy.vitamin_d = isNaN(food.vitamin_d/100*weight) ? 0 : food.vitamin_d/100*weight;
                foodEnergy.vitamin_e = isNaN(food.vitamin_e/100*weight) ? 0 : food.vitamin_e/100*weight;
                foodEnergy.vitamin_b1 = isNaN(food.vitamin_b1/100*weight) ? 0 : food.vitamin_b1/100*weight;
                foodEnergy.vitamin_b2 = isNaN(food.vitamin_b2/100*weight) ? 0 : food.vitamin_b2/100*weight;
                foodEnergy.vitamin_b6 = isNaN(food.vitamin_b6/100*weight) ? 0 : food.vitamin_b6/100*weight;
                foodEnergy.vitamin_b12 = isNaN(food.vitamin_b12/100*weight) ? 0 : food.vitamin_b12/100*weight;
                foodEnergy.vitamin_c = isNaN(food.vitamin_c/100*weight) ? 0 : food.vitamin_c/100*weight;
                foodEnergy.salt = isNaN(food.salt/100*weight) ? 0 : food.salt/100*weight;
            }
            
            this.liveModal3 = true;
            this.$nextTick().then(() => {
                this.signal = Object.assign({},foodEnergy);
            });
        },
        recipeEdit:function(data) {
            this.liveModal4 = true;
            this.$nextTick().then(() => {
                this.signal = Object.assign({},data);
            });
        },
        research:function(register) {
            if(register) {
                this.toastMsg = {message:"レシピを保存しました。"};
            } else {
                this.toastMsg = {message:"レシピを下書き保存しました。"};
            }
            this.getRecipe();
        },
    },
    mounted:function() {
        // this.getRecipe();
    },
    created:function() {
        this.getRecipe();
        this.getColumn();
    },
}
</script>