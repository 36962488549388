<style lang="scss" scoped>
@import "../../../../../sass/variables";
.banner-img {
    width: 100%;
    aspect-ratio: 3/1;
    object-fit: cover;
}
.profile {
    margin: 0 0.5rem;
    .info {
        display: flex;
        justify-content: space-around;
        margin: 0.5rem 0;
        .icon-wrap {
            display: flex;
            align-items: center;
            margin: 0 6rem 0 0;
            .icon {
                width: 50px;
                height: 50px;
                object-fit: cover;
                border-radius: 50%;
            }
        }
        .count {
            &:hover {
                cursor: pointer;
            }
            p {
                margin: 0;
                text-align: center;
                font-size: 0.8rem;
            }
        }

    }
    h2 {
        color: $brown-font;
        font-size: 1.1rem;
        padding: 0 0 0 0.6rem;
        margin: 0;
    }
    .note {
        background-color: #ffefcd;
        padding: 1rem 0.6rem;
        margin: 0.5rem 0 0;
        border-radius: 4px;
        p {
            font-size: 0.8rem;
            margin: 0;
        }
    }
    .sns {
        display: flex;
        align-items: center;
        margin: 1rem 0 0.4rem;
        .follow {
            width: 100px;
            text-align: center;
            background-color: #fff;
            color: $main-color-red;
            border: 2px solid $main-color-red;
            border-radius: 6px;
            padding: 0.1rem;
            transition: 0.3s;
            &:hover {
                cursor: pointer;
                box-shadow: 0 0 0 3px #f2e8d6;
            }
            &.is-active {
                background-color: $main-color-red;
                color: #fff;
            }
        }
    }
}
.profile-content {
    .menu {
        display: flex;
        margin: 0 0.6rem;
        &.is-recipe {
            span {
                border-bottom: 4px solid $main-color-red;
                &:first-of-type {
                    border-bottom: 2px solid $main-color-orange;
                }
            }
        }
        span {
            text-decoration: none;
            color: $brown-font;
            display: inline-block;
            width: 50%;
            text-align: center;
            font-size: 1rem;
            font-weight: bold;
            border-bottom: 2px solid $main-color-orange;
            padding: 0.2rem 0 0.5rem;
            transition: 0.3s;
            &:hover {
                cursor: pointer;
                opacity: 0.8;
            }
            &:first-of-type {
                border-right: 2px solid $border-color;
                border-bottom: 4px solid $main-color-red;
            }
        }
    }
    h3 {
        font-size: 1.1rem;
        color: $brown-font;
    }
    .search-area {
        padding: 1rem 0;
        .show-more {
            text-align: end;
            a {
                font-size: 0.8rem;
            }
        }
    }
    .title {
        position: relative;
        a {
            position: absolute;
            right: 0;
            bottom: 0;
            font-size: 0.8rem;
        }
    }
    .left-content {
        margin: 0 0.8rem;
        .category {
            border-top: 1px solid $border-color;
            padding: 1rem 0;
            .categories {
                display: flex;
                flex-wrap: wrap;
                div {
                    width: 50%;
                    h4 {
                        font-size: 1rem;
                        display: flex;
                        align-items: center;
                        margin: 0;
                        &::before {
                            font-family: "Font Awesome 5 Free";
                            font-weight: bold;
                            font-size: 1.6rem;
                            margin: 0 0.6rem 0 0;
                            width: 30px;
                            text-align: center;
                        }
                        &.meat::before {
                            content: '\f491';
                        }
                        &.vegetable::before {
                            content: '\f816';
                        }
                        &.fish::before {
                            content: '\f578';
                        }
                        &.noodles::before {
                            content: '\f7b6';
                        }
                        &.meal::before {
                            content: '\f5a7';
                        }
                        &.soup::before {
                            content: '\f0f4';
                        }
                        &.bento::before {
                            content: '\f466';
                        }
                        &.bread::before {
                            content: '\f805';
                        }
                        &.sweets::before {
                            content: '\f7ef';
                        }
                        &.drink::before {
                            content: '\f4e3';
                        }
                        &.others::before {
                            content: '\f2e7';
                        }
                        a {
                            text-decoration: none;
                            color: $black-font;
                        }
                    }
                }
            }
        }
        .ranking {
            border-top: 1px solid $border-color;
            padding: 1rem 0;
        }
    }
    .right-content {
        .search-area {
            margin: 0 0.8rem;
        }
        .title {
            margin: 0 0.8rem;
        }
    }
}
@media screen and (min-width: 1000px) {
    .profile {
        margin: 0 2rem;
    }
    .profile-content {
        .menu {
            margin: 0 2.2rem;
        }
        .left-content {
            margin: 0 2rem;
        }
        .right-content {
            margin: 0 1.2rem;
        }
    }
}
</style>
<template>
<div>
    <div v-if="isShow">
        <img v-if="profile.image_banner" class="banner-img" :src="`https://healtheat.s3.ap-northeast-1.amazonaws.com${profile.image_banner}`" alt="">
        <img v-if="!profile.image_banner" class="banner-img" :src="NoImgUrl" alt="">
        <div class="profile">
            <div class="info">
                <div class="icon-wrap">
                    <img v-if="profile.image_icon" class="icon" :src="`https://healtheat.s3.ap-northeast-1.amazonaws.com${profile.image_icon}`" alt="">
                    <img v-if="!profile.image_icon" class="icon" :src="NoImgUrl" alt="">
                    <h2 class="disp-pc">{{profile.name}}</h2>
                </div>
                <div @click="recipeScroll" class="count">
                    <p>レシピ</p>
                    <p>{{profile.recipeCount}}</p>
                </div>
                <div @click="openFollow(false)" class="count">
                    <p>フォロワー</p>
                    <p>{{profile.followedCount}}</p>
                </div>
                <div @click="openFollow(true)" class="count">
                    <p>フォロー</p>
                    <p>{{profile.followingCount}}</p>
                </div>
            </div>
            <h2 class="disp-sp">{{profile.name}}</h2>
            <div v-if="profile.note" class="note">
                <p>{{profile.note}}</p>
            </div>
            <div class="sns">
                <div v-if="this.user_uuid !== profile.uuid" @click="followClick" class="follow" :class="{'is-active' : isFollowed }">フォロー<span v-if="isFollowed">中</span></div>
                <div v-if="this.user_uuid == profile.uuid" @click="openProfile(profile)" class="follow is-active">編集する</div>
                <member-sns :snsLink="profile.sns_link"></member-sns>
            </div>
        </div>
        <adsense type="2" />
        <div id="scroll" class="profile-content">
            <div class="menu" :class="{'is-recipe' : pageQuery == 'recipe' }">
                <span @click="topScroll">トップ</span>
                <span @click="recipeScroll">レシピ</span>
            </div>
            <div v-show="pageQuery != 'recipe'" class="left-content">
                <div class="search-area">
                    <h3>レシピを検索</h3>
                    <search-component></search-component>
                    <div class="show-more">
                        <router-link :to="pageURL + '?page=recipe'">レシピ一覧({{profile.recipeCount}}品)>></router-link>
                    </div>
                </div>
                <div class="category">
                    <h3>カテゴリー別</h3>
                    <div class="categories">
                        <div v-for="(category,i) in profile.recipeCategories" :key="i">
                            <h4 :class="category.category.url_name">
                                <router-link :to="pageURL + '?page=recipe&category=' + category.category.id">{{category.category.name}}</router-link>
                            </h4>
                        </div>
                    </div>
                </div>
                <div class="ranking">
                    <div class="title">
                        <h3>人気のレシピ</h3>
                        <router-link :to="pageURL + '?page=recipe'">レシピ一覧({{profile.recipeCount}}品)>></router-link>
                    </div>
                    <div class="recipes-index">
                        <div class="recipe" v-for="(rank,i) in profile.recipeRanking" :key="i">
                            <router-link :to="`/recipe/${rank.id}`">
                                <img v-if="rank.thumbnail" class="thumbnail" :src="`https://healtheat.s3.ap-northeast-1.amazonaws.com${rank.thumbnail}`" alt="">
                                <img v-if="!rank.thumbnail" class="thumbnail" :src="NoImgUrl" alt="">
                            </router-link>
                            <div class="recipe-info">
                                <h4>
                                    <router-link :to="`/recipe/${rank.id}`">{{rank.name}}</router-link>
                                </h4>
                                <p>{{dateFormat(rank.created_at)}}</p>
                                <span class="cooking-time">{{rank.cooking_time}}分</span>
                                <span class="energy">{{rank.energy}}kcal</span>
                                <span class="favorite-count">{{rank.favorite_count_count}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-show="pageQuery == 'recipe'" class="right-content">
                <div class="search-area">
                    <h3>レシピ一覧</h3>
                    <search-component></search-component>
                </div>
                <div v-show="isCategory" class="title">
                    <h3 class="category-title">{{categoryName}}</h3>
                    <router-link :to="pageURL + '?page=recipe'">全てのレシピを見る>></router-link>
                </div>
                <div v-if="isSearch" class="vue-table table-responsive">
                    <v-table ref="vuetable" :api-mode="true" :api-url="searchUrl" pagination-path="" :fields="fields"
                        :noDataTemplate = "noDataMsg"
                        @vuetable:pagination-data="onPaginationData"
                        class="user-table member-recipe-table recipes-index"
                    >
                        <div slot="myfood" slot-scope="props" class="recipe">
                            <router-link :to="`/recipe/${props.rowData.id}`">
                                <img v-if="props.rowData.thumbnail" class="thumbnail" :src="`https://healtheat.s3.ap-northeast-1.amazonaws.com${props.rowData.thumbnail}`" alt="">
                                <img v-if="!props.rowData.thumbnail" class="thumbnail" :src="NoImgUrl" alt="">
                            </router-link>
                            <div class="recipe-info">
                                <h4>
                                    <router-link :to="`/recipe/${props.rowData.id}`">{{props.rowData.name}}</router-link>
                                </h4>
                                <p>{{dateFormat(props.rowData.created_at)}}</p>
                                <span class="cooking-time">{{props.rowData.cooking_time}}分</span>
                                <span class="energy">{{props.rowData.energy}}kcal</span>
                                <span class="favorite-count">{{props.rowData.favorite_count_count}}</span>
                            </div>
                        </div>
                    </v-table>
                </div>
                <div class="pagenation-wapper">
                    <div class="pagenation-area">
                        <v-page ref="pagination" @vuetable-pagination:change-page="onChangePage" :css="pageStyle"></v-page>
                    </div>
                </div>
            </div>
        </div>
        <login-info-modal v-if="liveModal" :signal="signal" @close="liveModal = false"></login-info-modal>
        <editor-modal v-if="liveModal2" :signal="signal" @close="liveModal2 = false"></editor-modal>
        <follow-modal v-if="liveModal3" :signal="signal" @close="liveModal3 = false"></follow-modal>
    </div>
</div>
</template>
<script>
import searchComponent from "./search-component.vue";
import memberSns from "./sns-link.vue";
import {NO_IMG_URL} from "../../../../common/constants";
import loginInfoModal from "../../../../components/login-info-modal.vue";
import Editor from "./editor-modal";
import followModal from "./follow-modal.vue";
import Api from "../../../../common/fetch-wrapper";
import {mapGetters} from "vuex";
import { USER_ARIES } from "../../../../store/modules/user-store";
import {Vuetable, VuetablePagination} from 'vuetable-2';
import {PAGE_STYLE_DEF,NO_DATA_MSG} from "../../../../components/vue-table2-config";
import dayjs from 'dayjs';
import Adsense from "../../../../components/adsense.vue";

const TABLE_FIELDS = [
    {name:"myfood", title:"レシピ",titleClass:"myfood-col"},
];

export default {
    props: {
        profile_id: "",
    },
    components: {
        "search-component" : searchComponent,
        "member-sns" : memberSns,
        "login-info-modal" : loginInfoModal,
        "editor-modal" : Editor,
        "follow-modal" : followModal,
        "v-table" : Vuetable,
        "v-page" : VuetablePagination,
        "adsense" : Adsense,
    },
    data:function() {
        return {
            pageURL: "",
            pageQuery: "",
            isShow: false,
            isSearch: false,
            isCategory: false,
            isFollowed: false,
            user_uuid: "",
            profile: {},
            categoryName: "",
            list: [],
            NoImgUrl: NO_IMG_URL,
            fields : TABLE_FIELDS,
            pageStyle : PAGE_STYLE_DEF,
            noDataMsg : NO_DATA_MSG,
            liveModal : false,
            liveModal2 : false,
            liveModal3 : false,
            signal: {},
        };
    },
    computed: {
        ...mapGetters(USER_ARIES, ["logined","userUUID"])
    },
    watch: {
        $route(to, from) {
            this.pageURL = this.$route.path;
            this.pageQuery = this.$route.query.page;
            this.getProfile();
            this.searchRecipe();

            if(this.pageQuery == "recipe") {
                const element = document.getElementById('scroll');
                const targetDOMRect = element.getBoundingClientRect();
                const targetTop = targetDOMRect.top + window.pageYOffset;
                window.scrollTo({
                    top: targetTop - 66,
                    behavior: 'smooth'
                })
            }
        }
    },
    methods: {
        getProfile:function() {
            Api.get("/member/profile/" + this.profile_id).then(x => {
                if(x.status !== 1) {
                    return location.href = "/not-found";
                }
                this.profile = x;

                if(this.logined) {
                    this.user_uuid = this.userUUID;
                    if(this.profile.followed[0]) {
                        this.isFollowed = true;
                    }
                }
                this.isShow = true;
            });
        },
        searchRecipe:function() {
            this.searchUrl = "/member/recipe/" + this.profile_id + location.search;
            this.isSearch = true;
            this.getcategory(this.$route.query.category);
        },
        getcategory:function(category_id) {
            this.isCategory = false;
            if(category_id) {
                Api.get("/recipe/category/id/" + category_id).then(x => {
                    this.categoryName = x.name;
                    this.isCategory = true;
                });
            }
        },
        onPaginationData:function(paginationData) {
            this.$refs.pagination.setPaginationData(paginationData);
            this.total = paginationData.total;
        },
        onChangePage:function(page) {
            this.$refs.vuetable.changePage(page);
        },
        followClick:function() {
            if(this.logined) {
                const form = new FormData();
                form.append("member_uuid",this.profile.uuid);
                Api.post("/member/followed/toggle", form, (d) => {
                    if(d.ok) {
                        this.isFollowed = false;
                        this.getProfile();
                    }
                });
            } else {
                this.liveModal = true;
                this.$nextTick().then(() => {
                    this.signal = Object.assign({});
                });
            }
        },
        openProfile:function(data = undefined) {
            this.liveModal2 = true;
            this.$nextTick().then(() => {
                if(!data) {
                    this.signal = {};
                    return;
                }
                this.signal = Object.assign({},data);
            });
        },
        openFollow:function(isFollowing = false) {
            const data = {};
            data.isFollowing = isFollowing;
            data.profileId = this.profile_id;
            this.liveModal3 = true;
            this.$nextTick().then(() => {
                this.signal = Object.assign({},data);
            });
        },
        topScroll:function() {
            this.$router.push({path:this.pageURL}).catch(err => {});
        },
        recipeScroll:function() {
            if(this.pageQuery == "recipe") {
                const element = document.getElementById('scroll');
                const targetDOMRect = element.getBoundingClientRect();
                const targetTop = targetDOMRect.top + window.pageYOffset;
                window.scrollTo({
                    top: targetTop - 66,
                    behavior: 'smooth'
                })
            } else {
                this.$router.push({path:this.pageURL + '?page=recipe'}).catch(err => {});
            }
        },
        dateFormat:function(date) {
            return dayjs(date).format("YYYY月MM年DD日");
        },
    },
    mounted:function() {
        this.pageURL = this.$route.path;
        this.pageQuery = this.$route.query.page;
        this.getProfile();
        this.searchRecipe();
    }

}
</script>