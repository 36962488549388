<style lang="scss" scoped>
@import "../../../../sass/variables";
.title_wrap {
    span {
        &::before {
            content: '\f02d';
            font-family: "Font Awesome 5 Free";
            font-weight: bold;
            font-size: 1.6rem;
            margin: 0 0.3rem 0 0;
        }
    }
}
.sns-share {
    margin: 0 0 1rem;
}
.column_content {
    h1 {
        font-size: 1.2rem;
        padding: 0 0.5rem;
    }
    .image {
        width: 100%;
        img {
            width: 100%;
            object-fit: cover;
            aspect-ratio: 1 / 1;
        }
    }
}
@media screen and (min-width: 1000px) {
    .column_content {
        padding: 1rem 2rem 4rem;
        .image img {
            aspect-ratio: 5 / 3;
        }
    }
}
</style>
<template>
<div class="user_contents">
    <div class="title_wrap">
        <span>コラム</span>
    </div>
    <search-component></search-component>
    <div class="column_content" v-if="showDetail">
        <h1>{{ column.title }}</h1>
        <div class="image">
            <img v-if="column.image" class="thumbnail" :src="`https://healtheat.s3.ap-northeast-1.amazonaws.com${column.image}`" :alt="`${column.title}の画像`">
            <img v-if="!column.image" class="thumbnail" :src="NoImgUrl" :alt="`${column.title}の画像`">
        </div>
        <adsense type="1" />
        <div class="sns-share">
            <social-sharing
                :url="`https://healtheat.jp/column/${column.id}\n\n`"
                :title="`${column.title}\n`"
                twitter-user="healtheat_jp"
                inline-template
            >
                <network class="button twitter" network="twitter">ポスト</network>
            </social-sharing>
            <social-sharing
                :url="`https://healtheat.jp/column/${column.id}`"
                :title="column.title"
                :description="column.title"
                inline-template
            >
                <network class="button line" network="line">LINE</network>
            </social-sharing>
            <social-sharing
                :url="`https://healtheat.jp/column/${column.id}`"
                :title="column.title"
                :description="column.description"
                inline-template
            >
                <network class="button facebook" network="facebook">シェア</network>
            </social-sharing>
            <social-sharing
                :url="`https://healtheat.jp/column/${column.id}`"
                :title="column.title"
                :description="column.description"
                :media="`https://healtheat.s3.ap-northeast-1.amazonaws.com${column.image}`"
                inline-template
            >
                <network class="button pinterest" network="pinterest">保存</network>
            </social-sharing>
        </div>
        <div class="mce-content-body user" v-html="column.body"></div>
        <adsense type="1" />
        <div class="column-new">
            <h2 class="recipe-title">新着コラム</h2>
            <div class="column row" v-for="(column,i) in columnList" :key="i">
                <div class="flex">
                    <div @click="columnClick(column)">
                        <img v-if="column.image" :src="`https://healtheat.s3.ap-northeast-1.amazonaws.com${column.image}`" :alt="`${column.title}の画像`">
                        <img v-if="!column.image" :src="NoImgUrl" :alt="`${column.title}の画像`">
                    </div>
                    <div>
                        <h3 @click="columnClick(column)" class="title">{{column.title}}</h3>
                        <p @click="columnClick(column)">{{column.body}}</p>
                    </div>
                </div>
            </div>
            <div class="more-recipe">
                <router-link to="/column">コラムをもっと見る</router-link>
            </div>
        </div>
        <adsense type="1" />
    </div>
</div>
</template>
<script>
import VueRouter from 'vue-router';
import searchComponent from "../recipe/search-component.vue"
import Api from "../../../common/fetch-wrapper";
import {NO_IMG_URL} from "../../../common/constants"
import Adsense from "../../../components/adsense.vue";

export default {
    props: {
        columnId: "",
    },
    components: {
        "search-component" : searchComponent,
        "adsense" : Adsense,
    },
    data() {
        return {
            showDetail: false,
            column: {},
            columnList: [],
            NoImgUrl: NO_IMG_URL,
        }
    },
    watch: {
        $route () {
            this.showDetail = false,
            this.getColumn();
        }
    },
    methods: {
        getColumn:function() {
            Api.get("/column/detail/" + this.columnId).then(x => {
                // レシピが公開されてない場合はリダイレクト
                if(x.status !== 2) {
                    return location.href = "/not-found";
                }
                this.column = x;

                document.title = `${this.column.title} | ヘルシート`;
                document.querySelector("meta[name='description']").setAttribute('content', this.column.description);
                document.querySelector("meta[name='thumbnail']").setAttribute('content', `https://healtheat.s3.ap-northeast-1.amazonaws.com${this.column.image}`);
                document.querySelector("meta[property='og:site_name']").setAttribute('content', this.column.title);
                document.querySelector("meta[property='og:title']").setAttribute('content', this.column.title);
                document.querySelector("meta[property='og:description']").setAttribute('content', this.column.description);
                document.querySelector("meta[property='og:image']").setAttribute('content', `https://healtheat.s3.ap-northeast-1.amazonaws.com${this.column.image}`);

                Api.get("/column/view/" + this.columnId);
                this.showDetail = true;
            });
        },
        getColumnList:function() {
            Api.get("/column/new").then(x => {
                this.columnList = x;
            });
        },
        columnClick:function(data) {
            const { isNavigationFailure, NavigationFailureType } = VueRouter;
            if(data.type == 1){
                this.$router.push({path:`/recipe-summary/${data.id}`}).catch(err => {
                    if(isNavigationFailure(err, NavigationFailureType.duplicated)) {
                        window.scrollTo({
                            top: 0,
                            behavior: "instant",
                        });
                    }
                });
            } else {
                this.$router.push({path:`/column/${data.id}`}).catch(err => {
                    if(isNavigationFailure(err, NavigationFailureType.duplicated)) {
                        window.scrollTo({
                            top: 0,
                            behavior: "instant",
                        });
                    }
                });
            }
        },
    },
    mounted:function() {
        this.getColumn();
        this.getColumnList();
    },
}
</script>